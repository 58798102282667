import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";

import { ApiResponse } from '../../../type/stamp_props'

export const previewData = createAsyncThunk<ApiResponse, {id:string, token:string}>(
    'previewData',
    async ({id, token}) => {
        try {
            const headers:any = {
                'Content-Type':'application/json',
                'Cache-Control': 'no-cache',
            };

            const response:any = await axios.request({
                url:`${process.env.REACT_APP_API_URL}api/preview_api0002`,
                method:'GET',
                headers:headers,
                params:{
                    // "consumer_id": auth.data.res_data.consumer_id,
                    // "retailer_id": auth.data.res_data.retailer_id
                    // "id": 'test@funkit.co.jp',
                    // "password": 'mKQ9HdgFrOGBuEBvq9s0'
                    "id": token
                }
            });

            let resData = response.data;
            resData.id = id;

            return  response.data;
        }catch (e:any) {
            console.log(e);
            if(e.response.statusText){
                return {
                    status: e.response.statusText,
                    data: e,
                };
            }else{
                return {
                    status: 'failed',
                    data: e,
                };
            }
        }
    }
);