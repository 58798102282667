import React from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from '../store';


import { setCampaignView } from '../slice/viewSlice';

import {
    reactTagManagerEvDetailBeforeClickPrevBtn,
    reactTagManagerEvDetailAfterClickPrevBtn,
    reactTagManagerEvAppConfirmClickPrevBtn,
    reactTagManagerEvClickAppConfirmCompletePrevBtn
} from '../../functions/function';

const Back:React.FC = () => {
    const dispatch = useDispatch();
    const auth:any = useSelector((state) => state.authentication);
    const viewState:Number = useSelector((state) => state.viewData.viewState);
    const campaignId:any = useSelector((state) => state.viewData.detailId);
    const campaignData:any = useSelector((state) => state.campaignData);
    const appConditionData:any = useSelector((state) => state.applicationConditionData);
    const prevViewState:any = useSelector((state) => state.viewData.prevViewState);

    const backListClick = () => {
        if(viewState === 1 && !campaignData.is_entry){
            reactTagManagerEvDetailBeforeClickPrevBtn(auth.data.res_data.send_id);
        }else if(viewState === 1 && campaignData.is_entry){
            reactTagManagerEvDetailAfterClickPrevBtn(auth.data.res_data.send_id);
        }else if(viewState === 2 && !appConditionData.is_apply){
            reactTagManagerEvAppConfirmClickPrevBtn(auth.data.res_data.send_id);
        }else if(viewState === 2 && appConditionData.is_apply){
            reactTagManagerEvClickAppConfirmCompletePrevBtn(auth.data.res_data.send_id);
        }

        if(campaignData.is_limited){
            dispatch(setCampaignView({viewState: prevViewState , detailId: campaignId, prevViewState: 3, isLimited: 1}));
        }else{
            dispatch(setCampaignView({viewState: prevViewState , detailId: campaignId, prevViewState: 3, isLimited: 2}));
        }

        if(viewState === 3 && prevViewState === 6) {
            dispatch(setCampaignView({viewState: 0, detailId: campaignId, prevViewState: viewState, isLimited: 0}));
        }

        if(viewState === 6 && prevViewState === 3) {
            dispatch(setCampaignView({viewState: 0, detailId: campaignId, prevViewState: viewState, isLimited: 0}));
        }
        
    };

    return (
        <section className='px-6 py-3 bg-white' onClick={backListClick}>
            <nav className='flex space-x-4 items-center'>
                <div className='lrArrow'>
                    <img src="img/icon_angle_left.png" className='w-full'/>
                </div>
                <div className='listBack'>
                    戻る
                </div>
            </nav>
        </section>
    );
}

export default Back;