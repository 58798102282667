import React, { useState } from 'react';

import ApplyBtn from './ApplyBtn';

type Props = {
    appConditionData:any,
    itemNum:number
}

const Prize:React.FC<Props> = ({appConditionData, itemNum}) => {
    return(
        <section className='mb-8'>
            {
                (() => {
                    if(itemNum === 0){
                        return (
                            <div className='flex justify-between mt-10 mb-5'>
                                <h3 className='prizeTitle'>景品応募</h3>
                            </div>
                        );
                    }
                })()
            }
            <div>
                <figure>
                    {
                        (() => {
                            if(appConditionData.banner_url){
                                return <div><img src={`${process.env.REACT_APP_IMG_URL}${appConditionData.banner_url}`} className='w-full'/></div>
                            }else{
                                return <img src="img/dummy1.png" className='w-full'/>
                            }
                        })()
                    }
                    <figcaption className='prizeTxt p-5'>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: appConditionData.application_condition
                        }}></div>
                        <ApplyBtn applicationConditionData={appConditionData} />
                    </figcaption>
                </figure>
            </div>
        </section>
    );
}

export default Prize;