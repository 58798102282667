import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";

import { ApiResponse } from '../../../type/stamp_props'
import { join } from 'path';

export const authentication = createAsyncThunk<ApiResponse, {id:string, alignment:string}>(
    'authentication',
    async ({id, alignment}) => {
        try {
            const headers:any = {
                'Content-Type':'application/json',
                'Cache-Control': 'no-cache'
            };

            const response:any = await axios.request({
                url:`${process.env.REACT_APP_API_URL}api/api0001`,
                method:'GET',
                headers:headers,
                params:{
                    "id": id,
                    "password": 'pass1234!',
                    "alignment": alignment
                }
            });

            let resData = response.data;
            resData.id = id;

            return  response.data;
        }catch (e:any) {
            console.log(e);
            if('message' in e){
                return {
                    status: e.message,
                    data: e,
                };
            }else{
                return {
                    status: 'failed',
                    data: e,
                };
            }
        }
    }
);