import React, { useEffect } from 'react';

import { useSelector } from '../component/store';

import {
    reactTagManagerEvViewCampaignItem
} from '../functions/function';

export function useOnScreen(targetRef: React.RefObject<HTMLElement>) {
    const auth:any = useSelector((state) => state.authentication);
    const viewState:Number = useSelector((state) => state.viewData.viewState);

    const observer = new IntersectionObserver(
        ([entry]) => {
            if (entry.isIntersecting) {
                if(viewState === 0 || viewState === 1){
                    reactTagManagerEvViewCampaignItem(targetRef.current?.dataset.id, auth.data.res_data.send_id);
                }
                return;
            }
        },
        {
            root: null,
            threshold: 0,
        }
    );

    useEffect(() => {
        if (targetRef.current) observer.observe(targetRef.current);

        return () => {
            observer.disconnect();
        };
    }, []);
}