import React, { useState ,useEffect} from 'react';
import { useDispatch } from 'react-redux';

import { useSelector, AppDispatch } from '../../store'

type Props = {
    point_limit:number,
    total_point:number,
    num_gift_point:number
}

const AppWarn:React.FC<Props> = ({point_limit, total_point, num_gift_point}) => {
    const [userGiftPointState, setUserGiftPointState] = useState<number>(0);
    const [displayState, setDisplayState] = useState<boolean>(false);

    let userGiftPointLimit:number = 0;

    useEffect(() => {
        window.scrollTo(0, 0);
        setDisplayState(false);
        setUserGiftPointState(0);

        userGiftPointLimit = point_limit - total_point;
        if(point_limit !== 0){
            if(point_limit >= total_point){
                if(userGiftPointLimit < num_gift_point){
                    setDisplayState(true);
                    setUserGiftPointState(userGiftPointLimit);
                }
            }

            if(userGiftPointLimit <= 0 && total_point > 0){
                setDisplayState(true);
            }
        }
    },[]);

    const displayOff = () =>{
        setDisplayState(false);
    }

    if(displayState){
        if(userGiftPointState > 0){
            return <>
                <div className='pointLimitPopupWrap fixed w-full h-full flex justify-center items-center'>
                    <div className='pointLimitPopupWhiteArea w-10/12 text-center py-6 rounded-md'>
                        <div>
                            期間上限は{point_limit}ポイントのため、<br/>景品ポイントのうち{userGiftPointState.toString()}ポイント が進呈されます
                        </div>
                        <div className='pointWrapClose w-6/12 mx-auto py-3 mt-6' onClick={displayOff}>閉じる</div>
                    </div>
                </div>
            </>
        }else{
            return <>
                <div className='pointLimitPopupWrap fixed w-full h-full flex justify-center items-center'>
                    <div className='pointLimitPopupWhiteArea w-10/12 text-center py-6 rounded-md'>
                        <div>
                            景品ポイントの上限に達しました。<br/>ご応募ありがとうございました。
                        </div>
                        <div className='pointWrapClose w-6/12 mx-auto py-3 mt-6' onClick={displayOff}>閉じる</div>
                    </div>
                </div>
            </>
        }
    }else{
        return <></>
    }
}

export default AppWarn;