import React from 'react';

const HistoryText:React.FC<{historyFlag:any}> = ({historyFlag}) => {
    if(historyFlag === 1){
        return(
            <p className="py-1">※景品への交換時期はキャンペーン詳細でご確認ください<br/>※上記WAON&nbsp;POINTお問い合わせ番号は、お問い合わせいただく際に必要です</p>
        );
    }else if(historyFlag === 2){
        return(
            <p className="py-1">※景品への交換時期はキャンペーン詳細でご確認ください<br/>※上記WAON&nbsp;POINTお問い合わせ番号は、お問い合わせいただく際に必要です</p>
        );
    }else if(historyFlag === 3){
        return(
            <p className="py-1">※景品への交換時期はキャンペーン詳細でご確認ください<br/>※上記WAON&nbsp;POINTお問い合わせ番号は、お問い合わせいただく際に必要です</p>
        );
    }else if(historyFlag === 4){
        return(
            <p className="py-1">※景品への交換時期はキャンペーン詳細でご確認ください<br/>※上記WAON&nbsp;POINTお問い合わせ番号は、お問い合わせいただく際に必要です</p>
        );
    }else if(historyFlag === 5){
        return(
            <p className="py-1">※景品への交換時期はキャンペーン詳細でご確認ください<br/>※上記WAON&nbsp;POINTお問い合わせ番号は、お問い合わせいただく際に必要です</p>
        );
    }else if(historyFlag === 6){
        return(
            <p className="py-1">※景品への交換時期はキャンペーン詳細でご確認ください<br/>※上記WAON&nbsp;POINTお問い合わせ番号は、お問い合わせいただく際に必要です</p>
        );
    }else{
        return(
            <p className="py-1">※景品への交換時期はキャンペーン詳細でご確認ください<br/>※上記WAON&nbsp;POINTお問い合わせ番号は、お問い合わせいただく際に必要です</p>
        );
    }
}

export default HistoryText;