import React, { useState ,useEffect} from 'react';
import { useDispatch } from 'react-redux';

import { useSelector, AppDispatch } from '../../store';

import { setHistoryView } from '../../slice/viewSlice';

import HistoryText from './HistoryText';

import {
    reactTagManagerEvViewAppHistory,
    reactTagManagerEvClickAppHistoryClose,
    isLaterCheck
} from '../../../functions/function';

const HistoryWrap:React.FC<{historyObj:any}> = ({historyObj}) => {
    const dispatch:AppDispatch = useDispatch();
    const auth:any = useSelector((state) => state.authentication);
    const campaignId:any = useSelector((state) => state.viewData.detailId);

    useEffect(() => {
        reactTagManagerEvViewAppHistory(campaignId, auth.data.res_data.send_id);
    },[]);

    const HistoryClick = () => {
        reactTagManagerEvClickAppHistoryClose(auth.data.res_data.send_id);
        dispatch(setHistoryView({historyState: 0}));
    };

    let isLaterFlag = 0;


    if(historyObj.length > 0){
        isLaterFlag = isLaterCheck(historyObj);

        let campaignId = '';
        let eoMemberId = '';
        
        if (historyObj.length > 0) {
            const data = historyObj[0];  // 最初の1要素を取得
            if ((data.inquiry_reference.match(/†-†/) || []).length === 1) {
                let inquiryStrAry = data.inquiry_reference.split('†-†');
                campaignId = inquiryStrAry[0];
                eoMemberId = data.inquiry_reference.replace(inquiryStrAry[0] + '†-†', '');
                let eoMemberIdAry = eoMemberId.match(/.{4}/g);
                if (eoMemberIdAry != null && eoMemberIdAry.length == 4) {
                    eoMemberId = eoMemberIdAry[0] + ' ' + eoMemberIdAry[1] + ' ' + eoMemberIdAry[2] + ' ' + eoMemberIdAry[3];
                } else if (eoMemberIdAry != null) {
                    eoMemberId = eoMemberIdAry[0] + ' ' + eoMemberIdAry[1] + ' ' + eoMemberIdAry[2];
                }
            } else {
                campaignId = data.inquiry_reference;
            }
        }

        return(
            <div className='historyParent fixed h-screen flex justify-center items-center px-6'>
                <section className='historyWrap w-full p-6 pb-10'>
                    <div>
                        <div className='flex justify-between mb-5'>
                            <h2 className='flex items-center space-x-2'>
                                <div className='w-5'><img src="img/clock_solid.png" className='w-full'/></div>
                                <div>応募履歴</div>
                                <div className='w-6 flex items-center ml-1'><img src={`${process.env.REACT_APP_IMG_URL}${auth.data.res_data.banner_url}`} className='w-full'/></div>
                            </h2>
                            <div className='w-5' onClick={HistoryClick}><img src="img/exit.png" className='w-full' /></div>
                        </div>
                        <div className='flex historyListFirst'>
                            <div className='w-3/12 flex items-center justify-center history-header-r-solid'>応募日</div>
                            <div className='w-9/12'>
                                <div className='flex history-header-b-solid'>
                                    <div className='w-6/12 text-center py-3 history-header-r-solid'>応募商品名</div>
                                    <div className='w-6/12 text-center py-3'>消費スタンプ数</div>
                                </div>
                                <div className='text-center py-1'>
                                    <div>キャンペーンID</div>
                                    <div>WAON&nbsp;POINTお問合わせ番号</div>
                                    <div className="text-black">{campaignId}</div>
                                    <div className="text-black">{eoMemberId}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='historyList'>
                        {historyObj.map((data:any, index:any) => {
                            return(
                                <div className='flex' key={index}>
                                    <div className='w-3/12 flex items-center justify-center dateArea'>{data.app_date}</div>
                                    <div className='w-9/12'>
                                        <div className='flex'>
                                            <div className='w-6/12 p-5 pointArea'>
                                                <p>{data.give_point}</p>
                                                <p>{data.app_name}</p>
                                            </div>
                                            <div className='w-6/12 p-5 items-center flex'>{data.use_stamp}スタンプ</div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <HistoryText historyFlag={isLaterFlag}/>
                </section>
            </div>
        );
    }else{
        return(
            <div className='historyParent fixed h-screen w-screen flex justify-center items-center px-6'>
                <section className='historyNothingWrap w-full p-6'>
                    <div>
                        <div className='flex justify-between mb-5'>
                            <h2 className='flex items-center space-x-2'>
                                <div className='w-5'><img src="img/clock_solid.png" className='w-full'/></div>
                                <div>応募履歴</div>
                            </h2>
                            <div className='w-5' onClick={HistoryClick}><img src="img/exit.png" className='w-full' /></div>
                        </div>
                    </div>
                    <div className='historyNotingTxt text-center'>現在ご応募いただいている景品はありません。</div>
                </section>
            </div>
        );
    }
}

export default HistoryWrap;