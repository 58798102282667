import React, { useEffect, useState } from 'react';
import { useSelector } from '../../store';

import {
    reactTagManagerEvViewError
} from '../../../functions/function';

const ErrorAll:React.FC = () => {
    const viewState:any = useSelector((state) => state.viewData);
    const auth:any = useSelector((state) => state.authentication);

    useEffect(() => {
        if('res_data' in auth.data){
            reactTagManagerEvViewError(viewState.errorViewId, auth.data.res_data.send_id);
        }else{
            reactTagManagerEvViewError(viewState.errorViewId, 'null');
        }
    },[]);

    return(
        <div className='flex items-center justify-center errorMain'>
            <div>
                <div className='w-3/6 mx-auto'><svg className="h-20 w-20 text-gray-400"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg></div>
                <h2 className='text-center mt-6'>エラー</h2>
                <div className='text-center mt-3'>
                    <p>正常に処理できませんでした。</p>
                    <p>再度お試しください。</p>
                    {
                        (() => {
                            if(viewState.errorDetail){
                                return <p>{viewState.errorDetail}</p>
                            }else{
                                return <p>MSG-ERROR-OTHER000001</p>
                            }
                        })()
                    }
                </div>
            </div>
        </div>
    );
}

export default ErrorAll;