import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector, AppDispatch } from '../../store'

import { setHistoryView } from '../../slice/viewSlice';

import { history } from '../../slice/history/history';

import {
    reactTagManagerEvDetailAfterHistoryClick
} from '../../../functions/function';

const HistoryBtn:React.FC = () => {
    const dispatch:AppDispatch = useDispatch();
    const auth:any = useSelector((state) => state.authentication);
    const campaignId:any = useSelector((state) => state.viewData.detailId);

    const HistoryClick = () => {
        reactTagManagerEvDetailAfterHistoryClick(campaignId, auth.data.res_data.send_id);
        dispatch(history({
            "consumer_id":auth.data.res_data.consumer_id,
            "retailer_id":auth.data.res_data.retailer_id,
            "campaign_id":campaignId,
            "token":auth.data.res_data.token
        }));
        dispatch(setHistoryView({historyState: 1}));
    };

    return (
        <div className='flex items-center historyBtn space-x-2 px-1' onClick={HistoryClick}>
            <div className='w-4'><img src="img/clock_solid_b.png" className='w-full' /></div>
            <div>応募履歴</div>
            <div className='w-4'><img src="img/upicon.png" className='w-full' /></div>
        </div>
    );
}

export default HistoryBtn;