import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PagePreviewState, ChangePagePreviewState, PagePreviewErrorState } from '../../../type/preview_props'

import { previewFunc, changePreviewFunc, previewErrorFunc } from './previewFunc'


const previewSlice = createSlice({
    name: 'previewData',
    initialState: {
        viewState:0,
        detailId:0,
        userIdState:'',
        tokenState:'',
        errorState:0,
        errorDetail:''
    },
    reducers: {
        setPreview: {
            reducer: previewFunc,
            prepare(payload: PagePreviewState){
                return {payload}
            }
        },
        changeView:{
            reducer: changePreviewFunc,
            prepare(payload: ChangePagePreviewState){
                return {payload}
            }
        },
        setPreviewError: {
            reducer: previewErrorFunc,
            prepare(payload: PagePreviewErrorState){
                return {payload}
            }
        }
    },
});

export const { setPreview, changeView, setPreviewError } = previewSlice.actions;
export default previewSlice.reducer;