import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from '../../store';

import { format, isBefore, isAfter, add, isEqual } from 'date-fns';

import { campaignListData2 } from '../../../type/stamp_props';

import { isBeforeEqual, isAfterEqual } from '../../../functions/function';
import { useOnScreen } from '../../../functions/useOnScreen';

import { setCampaignView } from '../../slice/viewSlice';

import { reactTagManagerEvClickCampaignDetail } from '../../../functions/function'

const Campaign:React.FC<{campaignListDataProp:campaignListData2}> = ({campaignListDataProp}) => {
    const dispatch = useDispatch();
    const auth:any = useSelector((state) => state.authentication);
    const viewState:Number = useSelector((state) => state.viewData.viewState);

    const campaignClick = (campaign_id:any, is_limited:any) => {
        reactTagManagerEvClickCampaignDetail(campaign_id , auth.data.res_data.send_id);
        dispatch(setCampaignView({viewState: 1 , detailId: campaign_id, prevViewState: 1, isLimited: is_limited}));
    };

    const today = new Date(format(new Date(), 'yyyy/MM/dd'));

    // const newFlag = isBeforeEqual(today,add(new Date(campaignListDataProp.target_period_start),{days: 7}));
    const newFlag = isBeforeEqual(today,add(new Date(campaignListDataProp.target_period_start.replace(' ', 'T')),{days: 7}));

    const targetRef = useRef(null);
    const componentView = useOnScreen(targetRef);

    return (
        <section className='mb-6 bg-white campaingItem' data-id={campaignListDataProp.campaign_id} ref={targetRef} onClick={() => {campaignClick(campaignListDataProp.campaign_id, campaignListDataProp.is_limited);}}>
            <div className='flex mb-2 pt-2 pl-2 campaignStatusTxt'>
                {newFlag ? <div className='newTag'>NEW</div> : <></>}
                {campaignListDataProp.hold_status === 1 ? <div>開催中</div> : <></>}
                {campaignListDataProp.hold_status === 2 ? <div>まもなく終了</div> : <></>}
                {campaignListDataProp.hold_status === 3 ? <div>応募受付中</div> : <></>}
                {campaignListDataProp.hold_status === 4 ? <div className='detailStatusEnd'>終了</div> : <></>}
            </div>
            <figure className='campaignImg relative'>
                {
                    (() => {
                        if(campaignListDataProp.banner_url !== ''){
                            return <div><img src={`${process.env.REACT_APP_IMG_URL}${campaignListDataProp.banner_url}`} className='w-full'/></div>
                        }else{
                            return <img src='img/dummy1.png' className='w-full' />
                        }
                    })()
                }

            </figure>
            <div className='campaignItemTxtWrap'>
                <div className='itemDeail'>
                    <h2>{campaignListDataProp.title}</h2>
                    <div className='entryDate'>
                        <p className='period'>対象期間:
                            <span className='dateA'>{format(new Date(campaignListDataProp.target_period_start.replace(' ', 'T')), 'MM/dd')}-{format(new Date(campaignListDataProp.target_period_end.replace(' ', 'T')), 'MM/dd')}</span>
                        </p>
                        <p className='enrtyPeriod'>景品応募対象期間:
                            <span className='dateB'>{format(new Date(campaignListDataProp.app_period_start.replace(' ', 'T')), 'MM/dd')}-{format(new Date(campaignListDataProp.app_period_end.replace(' ', 'T')), 'MM/dd')}</span>
                        </p>
                    </div>
                </div>
                {campaignListDataProp.is_entry ? <div className='px-3 py-1 text-center campaignItemStatus campaignItemStatusOn'>
                    エントリー済
                </div> : <div className='px-3 py-1 mt-2 text-center campaignItemStatus'>
                    未エントリー
                </div>}
            </div>
        </section>
    );
}

export default Campaign;