import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import axios from "axios";
import { format, isBefore, isAfter, add, isEqual } from 'date-fns';

import { useSelector } from '../../store';

import Stamp from '../../main/detail/Stamp';
import Prize from '../../main/detail/Prize';
import PreviewAppParts from './PreviewAppParts';

import { isBeforeEqual, isAfterEqual } from '../../../functions/function';

import { setPreview, changeView, setPreviewError } from '../../slice/preview/previewSlice';

const PreviewWrap:React.FC = () => {
    const dispatch = useDispatch();
    const [campaignData, setCampaignData] = useState<any>({});
    const [campaignDetailData, setCampaignDetailData] = useState<any>({});
    const [campaignId, setCampaignId] = useState<any>('');
    const campaignIdParamState:Number = useSelector((state) => state.previewViewData.detailId);
    const previewTokenState:string = useSelector((state) => state.previewViewData.tokenState);
    const previewUserIdState:string = useSelector((state) => state.previewViewData.userIdState);
    const [loadFlag, setLoadFlag] = useState<boolean>(false);

    let viewStampCardStamps:any[] = [];
    for(let i = 0; i < 20; i++){
        viewStampCardStamps.push(false);
    }

    const getCampaigns = async (id:Number): Promise<void> => {
        const headers:any = {
            'Content-Type':'application/json',
            'Cache-Control': 'no-cache',
            'Authorization':previewTokenState
        };

        if(campaignId !== null || campaignId !== ''){
            await axios.request({
                url:`${process.env.REACT_APP_API_URL}api/preview_api0002`,
                method:'GET',
                headers:headers,
                params:{
                    "id": previewUserIdState,
                    "campaign_id": id,
                }
            }).then((response) =>{
                if(response.data.res_data.campaign_list.length !== 0 && response.data.res_data.stamp_detail.length !== 0){
                    setCampaignData({...response.data.res_data.campaign_list[0]});
                    setCampaignDetailData({...response.data.res_data.stamp_detail[0]});
                }else{
                    setCampaignData({});
                    setCampaignDetailData({});
                }
                setLoadFlag(true);
            }).catch((error) =>{
                dispatch(setPreview({
                    viewState: 2,
                    detailId: 0,
                    tokenState: '',
                    userIdState: ''
                }));
                dispatch(setPreviewError({
                    errorState:1,
                    errorDetail:error.response.data.error_info.error_details
                }));
                setLoadFlag(true);
            });
        }
    };

    const clickGetCampaignBtn = () => {
        setLoadFlag(false);
        dispatch(changeView({
            viewState: 1,
            detailId: campaignId
        }));
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.set('id',campaignId);
        window.history.replaceState("", "", `?${urlSearchParams.toString()}`);
        setCampaignData({});
        getCampaigns(campaignId);
    };

    const clickLogOutBtn = () => {
        const storage = localStorage;
        storage.removeItem('token');
        storage.removeItem('userid');
        window.location.href = '?preview=true';
        // const urlSearchParams = new URLSearchParams(window.location.search);
        // urlSearchParams.delete('id');
        // dispatch(changeView({
        //     viewState: 0,
        //     detailId: 0
        // }));
    };

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        let id:string|null = urlSearchParams.get('id');
        setCampaignId(id);
        getCampaigns(campaignIdParamState);
    },[]);

    const today = new Date(format(new Date(), 'yyyy/MM/dd'));

    // const newFlag = isBeforeEqual(today,add(new Date(campaignData.target_period_start),{days: 7}));

    if(!loadFlag){
        return (
            <>
                <div className='campaginPreviewListWrap p-6 pb-0'>
                    <input type="text" value={campaignId} className='campaignIdInput' onChange={(e)=>setCampaignId(e.target.value)}/>
                    <button className='campaignIdBtn' onClick={clickGetCampaignBtn}>キャンペーンプレビュー表示</button>
                    <button className='logoutBtn' onClick={clickLogOutBtn}>ログアウト</button>
                    <h1 className='appTitle'>ウエルシアアプリ・スタンプラリープレビュー</h1>
                </div>
                <div className='flex justify-center items-center h-screen'>
                    <div className='w-1/3'>
                        <img src="img/spinner.gif" className='w-full'/>
                        <div className='readTxt'>読み込み中</div>
                    </div>
                </div>
            </>
        )
    }

    if(Object.keys(campaignData).length !== 0){
        return (
            <>
                <div className='campaginPreviewListWrap p-6 pb-0'>
                    <input type="text" value={campaignId} className='campaignIdInput' onChange={(e)=>setCampaignId(e.target.value)}/>
                    <button className='campaignIdBtn' onClick={clickGetCampaignBtn}>キャンペーンプレビュー表示</button>
                    <button className='logoutBtn' onClick={clickLogOutBtn}>ログアウト</button>
                    <h1 className='appTitle'>ウエルシアアプリ・スタンプラリープレビュー</h1>
                </div>

                <div className='campaginPreviewListWrap p-6'>
                    <section className='mb-6 bg-white campaingItem' data-id={campaignData.campaign_id}>
                        <div className='flex mb-2 pt-2 pl-2 campaignStatusTxt'>
                            <div className='newTag'>NEW</div>
                            <div>開催中</div>
                        </div>
                        <figure className='campaignImg relative'>
                            {
                                (() => {
                                    if(campaignData.banner_url !== ''){
                                        return <div><img src={`${process.env.REACT_APP_IMG_URL}${campaignData.banner_url}`} className='w-full'/></div>
                                    }else{
                                        return <img src='img/dummy1.png' className='w-full' />
                                    }
                                })()
                            }

                        </figure>
                        <div className='campaignItemTxtWrap'>
                            <div className='itemDeail'>
                                <h2>{campaignData.title}</h2>
                                <div className='entryDate'>
                                    <p className='period'>対象期間:
                                        <span className='dateA'>{format(new Date(campaignData.target_period_start.replace(' ', 'T')), 'MM/dd')}-{format(new Date(campaignData.target_period_end.replace(' ', 'T')), 'MM/dd')}</span>
                                    </p>
                                    <p className='enrtyPeriod'>景品応募対象期間:
                                        <span className='dateB'>{format(new Date(campaignData.app_period_start.replace(' ', 'T')), 'MM/dd')}-{format(new Date(campaignData.app_period_end.replace(' ', 'T')), 'MM/dd')}</span>
                                    </p>
                                </div>
                            </div>
                            <div className='px-3 py-1 mt-2 text-center campaignItemStatus'>
                                未エントリー
                            </div>
                        </div>
                    </section>
                </div>

                <div className='campaginPreviewListWrap p-6 pb-0'>
                    <h2 className='appTitle'>詳細画面</h2>
                </div>
                <div className='preview relative'>
                    <div className='px-6 pb-24'>
                        <section className='detailFirstArea'>
                            <div className='flex campaignStatusTxt mb-3'>
                                <div className='newTag'>NEW</div>
                                <div>開催中</div>
                            </div>
                            <figure>
                                {
                                    (() => {
                                        if(campaignDetailData.banner_url){
                                            return <div><img src={`${process.env.REACT_APP_IMG_URL}${campaignDetailData.banner_url}`} className='w-full'/></div>
                                        }else{
                                            return <img src="img/dummy1.png" className='w-full'/>
                                        }
                                    })()
                                }
                            </figure>
                            <h2 className='my-4'>{campaignDetailData.title}</h2>
                        </section>
                        <section className='mt-6 mb-5'>
                            <div className='flex justify-between'>
                                <div className='flex'><div className='exEntry px-2 py-1'>エントリー済</div></div>
                                <div className='flex items-center historyBtn space-x-2 px-1'>
                                    <div className='w-4'><img src="img/clock_solid_b.png" className='w-full' /></div>
                                    <div>応募履歴</div>
                                    <div className='w-4'><img src="img/upicon.png" className='w-full' /></div>
                                </div>
                            </div>
                        </section>

                        <section className='stampcardWrap my-3 px-6 pt-3 pb-0'>
                            <h3 className='stampCardTitle text-center mb-3'>スタンプカード</h3>
                            <div className='stampArea p-3'>
                                <div className='grid grid-cols-5'>
                                    {viewStampCardStamps.map((item:any, idx) => <Stamp getStampProp={item} key={idx}/>)}
                                </div>
                            </div>
                            <div className='flex cardCntArea py-3'>
                                <div className='w-3/6 text-center'>
                                    スタンプ:<span className='stampCardNum'>0</span>個
                                </div>
                                <div className='w-3/6 text-center'>
                                    カード:<span className='stampCardNum'>1</span>枚目
                                </div>
                            </div>
                        </section>
                        <section className='stampSortTxt'>※スタンプ進呈順に並んでおります。表示された日付はお買い物された日付です。</section>

                        {
                            (() => {
                                if(campaignDetailData.app_condition_list){
                                    return campaignDetailData.app_condition_list.map((item:any, index:number) => <Prize appConditionData={item} itemNum={index} key={index}/>)
                                }
                            })()
                        }
                        <section className='detailSecondArea'>
                            <div className='p-2'>
                                <h3 className='mb-1'>対象期間</h3>
                                <div className='px-2'><span>{format(new Date(campaignDetailData.target_period_start.replace(' ', 'T')), 'yyyy/MM/dd')}</span><span>-</span><span>{format(new Date(campaignDetailData.target_period_end.replace(' ', 'T')), 'yyyy/MM/dd')}</span></div>
                            </div>
                            <div className='p-2'>
                                <h3 className='mb-1'>景品応募対象期間</h3>
                                <div className='px-2'><span>{format(new Date(campaignDetailData.app_period_start.replace(' ', 'T')), 'yyyy/MM/dd')}</span><span>-</span><span>{format(new Date(campaignDetailData.app_period_end.replace(' ', 'T')), 'yyyy/MM/dd')}</span></div>
                            </div>
                            <div className='p-2'>
                                <h3 className='mb-1'>スタンプ条件</h3>
                                <div className='px-2 detailSecondAreaString' dangerouslySetInnerHTML={{__html:campaignDetailData.stamp_condition}}></div>
                            </div>
                            <div className='p-2'>
                                <h3 className='mb-1'>対象店舗</h3>
                                <div className='px-2 detailSecondAreaString' dangerouslySetInnerHTML={{__html:campaignDetailData.target_store}}></div>
                            </div>
                            <div className='p-2'>
                                <h3 className='mb-1'>景品</h3>
                                <div className='px-2 detailSecondAreaString' dangerouslySetInnerHTML={{__html:campaignDetailData.freebie_content}}></div>
                            </div>
                        </section>
                        <section className='precaution p-6 my-8'>
                            <h2 className='pb-3'>ご利用上のご注意</h2>
                            <div dangerouslySetInnerHTML={{__html:campaignDetailData.usage_notice}}></div>
                        </section>
                    </div>
                    <nav className='entryBtnWrap p-6 absolute bottom-0 right-0 w-full'>
                        <div className='text-center p-5'>上記確認のうえエントリー</div>
                    </nav>
                </div>

                <div className='campaginPreviewListWrap p-6 pb-0'>
                    <h2 className='appTitle'>応募画面</h2>
                </div>

                {campaignDetailData.app_condition_list.map((item:any, index:number) => <PreviewAppParts appConditionData={item} key={index}/>)}
            </>
        )
    }else{
        return (
            <>
                <div className='campaginPreviewListWrap p-6 pb-0'>
                    <input type="text" value={campaignId} className='campaignIdInput' onChange={(e)=>setCampaignId(e.target.value)}/>
                    <button className='campaignIdBtn' onClick={clickGetCampaignBtn}>キャンペーンプレビュー表示</button>
                    <button className='logoutBtn' onClick={clickLogOutBtn}>ログアウト</button>
                    <h1 className='appTitle'>ウエルシアアプリ・スタンプラリープレビュー</h1>
                </div>
                {
                    (() => {
                        if(campaignId){
                            return (
                                <div className='campaginPreviewListWrap p-6'>
                                    <section className='detailSecondArea'>
                                        お探しのキャンペーンはございません。
                                    </section>
                                </div>
                            )
                        }
                    })()
                }
            </>
        )
    }
}

export default PreviewWrap;