import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector, AppDispatch } from '../../store'

import { setChangeView, setCofirmStateView, setError } from '../../slice/viewSlice';

import ConfirmTitle from './ConfirmTitle';
import AppWarn from './AppWarn';
import ErrorDialog from '../error/ErrorDialog';

import axios from "axios";

import { format, isBefore, isAfter, add, isEqual } from 'date-fns';

import {
    reactTagManagerEvViewAppConfirm,
    reactTagManagerEvAppConfirmClickAppBtn,
    reactTagManagerEvViewAppConfirmComplete,
    reactTagManagerEvAppConfirmComplete,
    reactTagManagerEvAppConfirmClickPrevBtn,
    reactTagManagerEvClickAppConfirmCompletePrevBtn,
    reactTagManagerEvClickAppConfirmStampListBackBtn
} from '../../../functions/function';

const Confirm: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const campaignData: any = useSelector((state) => state.campaignData);
    const appConditionData: any = useSelector((state) => state.applicationConditionData);
    const auth: any = useSelector((state) => state.authentication);
    const campaignId: any = useSelector((state) => state.viewData.detailId);
    const errorState: any = useSelector((state) => state.viewData.errorState);
    // const errorState: any = 1;
    const confirmState: Number = useSelector((state) => state.viewData.confirmState);
    const viewState: Number = useSelector((state) => state.viewData.viewState);
    const prevViewState: any = useSelector((state) => state.viewData.prevViewState);

    const [onConfirmState, setOnConfirmState] = useState<Number>(confirmState);
    const [confirmCssState, setConfirmCssState] = useState('text-center confirmAfterStamp');
    const [unconsumedStampState, setUnconsumedStampState] = useState(campaignData.unconsumed_stamp);
    const [limitState, setLimitStateState] = useState(false);

    const today: string = format(new Date(), 'yyyy年MM月dd日');

    useEffect(() => {
        setLimitStateState(false);

        if (prevViewState !== 3) {
            dispatch(setCofirmStateView({ confirmState: 0 }));
        } else {
            setUnconsumedStampState(unconsumedStampState - appConditionData.num_stamp_require_app);
        }

        if(campaignData.point_limit !== 0 && campaignData.point_limit <= campaignData.total_point){
            setLimitStateState(true);
        }

        reactTagManagerEvViewAppConfirm(campaignId, auth.data.res_data.send_id);
    }, []);

    useEffect(() => {
        if (onConfirmState !== 0) {
            reactTagManagerEvViewAppConfirmComplete(campaignId, auth.data.res_data.send_id);
            setConfirmCssState('text-center confirmAfterStamp confirmAfterStampOnly');
        }
    }, [onConfirmState]);

    const backToStampList = () => {
        if (confirmState) {
            reactTagManagerEvClickAppConfirmStampListBackBtn(auth.data.res_data.send_id);
        } else {
            reactTagManagerEvAppConfirmClickPrevBtn(auth.data.res_data.send_id);
        }

        dispatch(setChangeView({ viewState: 0, prevViewState: viewState }));
        dispatch(setCofirmStateView({ confirmState: 0 }));
    };

    const backToStampCard = () => {
        reactTagManagerEvClickAppConfirmCompletePrevBtn(auth.data.res_data.send_id);
        dispatch(setChangeView({ viewState: 1, prevViewState: viewState }));
        dispatch(setCofirmStateView({ confirmState: 0 }));
    };

    const confirmBtn = async () => {
        reactTagManagerEvAppConfirmClickAppBtn(campaignId, auth.data.res_data.send_id);
        const headers: any = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Cache-Control': 'no-cache',
            'Authorization': auth.data.res_data.token
        };

        setOnConfirmState(1);
        dispatch(setCofirmStateView({ confirmState: 1 }));

        await axios.request({
            url: `${process.env.REACT_APP_API_URL}api/api0005`,
            method: 'POST',
            headers: headers,
            params: {
                "consumer_id": auth.data.res_data.consumer_id,
                "retailer_id": auth.data.res_data.retailer_id,
                "campaign_id": campaignId,
                "application_condition_id": appConditionData.application_condition_id,
                "application_condition_is_later": appConditionData.is_later
            }
        }).then((response) => {
            setOnConfirmState(2);
            dispatch(setCofirmStateView({ confirmState: 2 }));
            reactTagManagerEvAppConfirmComplete(campaignId, auth.data.res_data.send_id);
            setUnconsumedStampState(unconsumedStampState - response.data.res_data.num_stamp_require_app);
        }).catch((error) => {
            console.log(error);

            setOnConfirmState(0);
            dispatch(setCofirmStateView({ confirmState: 0 }));

            dispatch(setError({
                errorState: 1,
                errorDetail: error.response.data.error_info.error_details,
                errorViewId: 'screen_app_verify'
            }));
        });
    };

    let isLater = 1;
    if(!appConditionData.is_later){
        isLater = appConditionData.is_later;
    }

    return (
        <>
            <section className='px-6 confirmWrap'>
                <ConfirmTitle />
                <div>
                    {
                        (() => {
                            if (appConditionData.banner_url) {
                                return <div><img src={`${process.env.REACT_APP_IMG_URL}${appConditionData.banner_url}`} className='w-full' /></div>
                            } else {
                                return <div><img src="img/dummy1.png" className='w-full' /></div>
                            }
                        })()
                    }
                    <div className='p-5 conrfirmTxt'>
                        <div dangerouslySetInnerHTML={{__html:appConditionData.application_condition}}></div>
                        {confirmState ? <div className='conrfirmTxtInner mt-3'>ご応募日：{today}</div> : <></>}
                    </div>
                </div>
                <div className='mt-6'>
                    {onConfirmState === 0 ? <div className='text-center confirmAfterStamp'>消費スタンプ：<span>{appConditionData.num_stamp_require_app}</span>スタンプ</div> : <></>}
                    <div className={confirmCssState}>(保有スタンプ数：<span>{unconsumedStampState}</span>スタンプ)</div>
                </div>
                <div className='mt-3'>
                    {
                        (() => {
                            if (appConditionData.remaining_stamp <= 0) {
                                if(limitState){
                                    return <>
                                        <div className='confirmBtn text-center p-5 disableBtn'>応募する</div>
                                        <div className='confirmBackBtn text-center p-5 mt-3' onClick={backToStampCard}>スタンプカードへ戻る</div>
                                    </>
                                }else if (confirmState === 2) {
                                    return (
                                        <>
                                            <div className='confirmBtn text-center p-5' onClick={backToStampCard}>スタンプカードへ戻る</div>
                                            <div className='confirmBackBtn text-center p-5 mt-3' onClick={backToStampList}>スタンプ一覧へ戻る</div>
                                        </>
                                    )
                                } else if (confirmState === 1) {
                                    return <div className='confirmBtn text-center p-5 disableBtn'>応募処理中</div>
                                } else {
                                    return (
                                        <>
                                            <div className='confirmBtn text-center p-5' onClick={confirmBtn}>応募する</div>
                                            <div className='confirmBackBtn text-center p-5 mt-3' onClick={backToStampCard}>スタンプカードへ戻る</div>
                                        </>
                                    )
                                }
                            } else {
                                return <div className='confirmBtn text-center p-5' onClick={backToStampCard}>スタンプカードへ戻る</div>
                            }
                        })()
                    }
                </div>
            </section>
            {
                (() => {
                    if(errorState === 1){
                        return <ErrorDialog errorProp={1} />
                    }else{
                        return <AppWarn point_limit={campaignData.point_limit} total_point={campaignData.total_point} num_gift_point={appConditionData.num_gift_point} />
                    }
                })()
            }
        </>
    );
}

export default Confirm;