import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import ListWrap from './list/ListWrap'
import DetailWrap from './detail/DetailWrap'
import Confirm from './confirm/Confirm'
import Faq from './Faq'
import Notice from './Notice'
import ErrorAll from './error/ErrorAll'
import UpIcon from './UpIcon';

import { AppDispatch } from '../../component/store'

import { useSelector } from '../store';

import { authentication } from '../slice/authentication/authentication';
import { setChangeView, setError } from '../slice/viewSlice';
import { reactTagManagerEvViewCampaignList } from '../../functions/function';

const Main:React.FC<{param:any}> = ({param}) => {
    const dispatch: AppDispatch = useDispatch();
    const viewState:Number = useSelector((state) => state.viewData.viewState);
    const auth:any = useSelector((state) => state.authentication);

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    useEffect(() => {
        if(auth.status === 'waiting'){
            if(param.status){
                dispatch(authentication({id:param.id, alignment:param.alignment }));
                dispatch(setChangeView({viewState: 4, prevViewState: viewState}));

                return;
            }else{
                dispatch(setChangeView({viewState: 5, prevViewState: viewState}));
                dispatch(setError({
                    errorState:1,
                    errorDetail:'MSG-ERROR-Local000101',
                    errorViewId:'screen_campaign'
                }));

                return;
            }
        }

        if(auth.data.res_code === 200){
            dispatch(setChangeView({viewState: 0, prevViewState: viewState}));
            dispatch(setError({
                errorState:0,
                errorDetail:'',
                errorViewId:''
            }));

            return;
        }

        if(auth.status === 'idle' && auth.data.res_code !== 200){
            dispatch(setChangeView({viewState: 5, prevViewState: viewState}));
            dispatch(setError({
                errorState:1,
                errorDetail:'MSG-ERROR-Local000101',
                errorViewId:'screen_campaign'
            }));

            return;
        }
    },[auth]);

    if(viewState === 0){
        reactTagManagerEvViewCampaignList(auth.data.res_data.send_id);
        return (
            <>
                <ListWrap />
                <UpIcon />
            </>
        );
    }else if(viewState === 1){
        return (
            <>
                <DetailWrap />
                <UpIcon />
            </>
        );
    }else if(viewState === 2){
        return (
            <>
                <Confirm />
                <UpIcon />
            </>
        );
    }else if(viewState === 3){
        return (
            <>
                <Faq />
            </>
        );
    }else if(viewState === 4){
        return (
            <div className='flex justify-center items-center h-screen'>
                <div className='w-1/3'>
                    <img src="img/spinner.gif" className='w-full'/>
                    <div className='readTxt'>読み込み中</div>
                </div>
            </div>
        );
    }else if(viewState === 5){
        return (
            <ErrorAll />
        );
    }else if(viewState === 6){
        return (
            <>
                <Notice />
            </>
        );
    }else{
        return (
            <></>
        );
    }
}

export default Main;