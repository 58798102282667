import React, { useEffect } from 'react';
import { Provider } from 'react-redux'
import './css/style.css';

import './component/main/Main'
import Header from './component/Header';
import Main from './component/main/Main';
import PreviewWrap from './component/preview/PreviewWrap'

import { reactTagManagerInit } from './functions/function';
import { store } from './component/store';

import { urlParamCheck } from './functions/function';

function App() {
    let previewBool:any = false;
    const url:URL = new URL(window.location.href);
    const params:URLSearchParams = url.searchParams;
    const previewParam = params.get('preview');
    if(previewParam){
        previewBool = previewParam;
    }

    useEffect(() => {
        reactTagManagerInit();
    },[]);

    return (
        <Provider store={store}>
            <Header />
            <main className='bg-defaultGray'>
                {
                    (() => {
                        if(previewBool === 'true'){
                            return <PreviewWrap/>
                        }else{
                            return <Main param={urlParamCheck()}/>
                        }
                    })()
                }
            </main>
        </Provider>
    );
}

export default App;
