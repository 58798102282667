import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PageCampaignState, PageErrorState, PageViewState, PageConfirmState, PageHistoryState } from '../../type/stamp_props'

import { campaignViewFunc, viewChangeFunc, setHistoryFunc, setCofirmStateFunc, errorFunc } from './viewFunc'


const viewSlice = createSlice({
    name: 'viewData',
    initialState: {
        viewState:3,
        errorState:0,
        errorDetail:'',
        errorViewId:'',
        historyState:0,
        detailId:0,
        isLimited:1,
        confirmState:0,
        prevViewState:3
    },
    reducers: {
        setCampaignView: {
            reducer: campaignViewFunc,
            prepare(payload: PageCampaignState){
                return {payload}
            }
        },
        setChangeView: {
            reducer: viewChangeFunc,
            prepare(payload: PageViewState){
                return {payload}
            }
        },
        setHistoryView: {
            reducer: setHistoryFunc,
            prepare(payload: PageHistoryState){
                return {payload}
            }
        },
        setCofirmStateView: {
            reducer: setCofirmStateFunc,
            prepare(payload: PageConfirmState){
                return {payload}
            }
        },
        setError: {
            reducer: errorFunc,
            prepare(payload: PageErrorState){
                return {payload}
            }
        }
    },
});

export const { setCampaignView, setChangeView, setHistoryView, setCofirmStateView, setError } = viewSlice.actions;
export default viewSlice.reducer;