import {isBefore, isAfter, isEqual, parseISO } from 'date-fns';
import TagManager from 'react-gtm-module';

export const sampleFunc= () => {

};

export const isBeforeEqual= (checkDate1:Date, checkDate2:Date):boolean => {
    const result:boolean = isBefore(checkDate1,checkDate2) || isEqual(checkDate1,checkDate2);
    return result;
};

export const isAfterEqual= (checkDate1:Date, checkDate2:Date):boolean => {
    const result:boolean = isAfter(checkDate1,checkDate2) || isEqual(checkDate1,checkDate2);
    return result;
};

export const urlParamCheck = () => {
    const url:URL = new URL(window.location.href);
    const params:URLSearchParams = url.searchParams;
    const idParams:any = params.get('id');
    const alignmentParams:any = params.get('alignment');

    let result:any = {
        status:true,
        id:"",
        alignment:""
    };

    if(idParams === null){
        result.status = false;
        return result;
    }

    if(alignmentParams === null){
        result.status = false;
        return result;
    }

    // console.log('encodeURIComponent(idParams)');
    // console.log(encodeURIComponent(idParams));
    result.alignment = alignmentParams;
    // result.id = idParams;
    result.id = encodeURIComponent(idParams.replace(/ /g, '+'));

    return result;
};

export const sliceByNumber = (array:any[], number:number) => {
    const length = Math.ceil(array.length / number)
    return new Array(length).fill(false).map((_, i) =>
      array.slice(i * number, (i + 1) * number)
    )
}

declare const window: WindowWithDataLayer;
type WindowWithDataLayer = Window & {
    dataLayer: Record<string, any>[];
};

export const isLaterCheck = (historyObj: any) => {
    let isLaterFlags:any = {
        one:0,
        two:0,
        three:0
    };
    let isLaterFlagCalc = 0;
    let isLaterFlag = 0;

    historyObj.map((data:any, index:any) => {
        if(data.is_later === 1){
            isLaterFlags.one = 1
        }

        if(data.is_later === 2){
            isLaterFlags.two = 1
        }

        if(data.is_later === 3){
            isLaterFlags.three = 1
        }
    });

    Object.keys(isLaterFlags).forEach((key) => {
        isLaterFlagCalc += isLaterFlags[key];
    });

    if(isLaterFlagCalc === 1){
        if(isLaterFlags.one === 1){
            isLaterFlag = 1;
        }

        if(isLaterFlags.two === 1){
            isLaterFlag = 2;
        }

        if(isLaterFlags.three === 1){
            isLaterFlag = 3;
        }
    }else if(isLaterFlagCalc === 2){
        if(isLaterFlags.one === 1){
            if(isLaterFlags.two === 1){
                isLaterFlag = 4;
            }

            if(isLaterFlags.three === 1){
                isLaterFlag = 5;
            }
        }

        if(isLaterFlags.two === 1){
            if(isLaterFlags.three === 1){
                isLaterFlag = 6;
            }
        }
    }else{
        isLaterFlag = 7;
    }

    return isLaterFlag;
};

export const reactTagManagerInit = () => {
    TagManager.initialize({
        gtmId: `${process.env.REACT_APP_GTM_ID}`
    });
};

export const reactTagManagerEvViewCampaignList = (idStr: string) => {
    const data = {
        event: 'campaignListViewEv',
        get_key: `campaign_list`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvCampaignClickFaqBtn = (idStr: string) => {
    const data = {
        event: 'campaignFaqViewEv',
        get_key: `campaign_faq`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickCampaignDetail = (str: any, idStr: string) => {
    const data = {
        event: 'campaignDetailClickEv',
        get_key: `campaign_detail_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickCampaignFloatBtn = (idStr: string) => {
    const data = {
        event: 'campaignFloatBtnClickEv',
        get_key: `campaign_floating`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvDetailBeforeEntry = (str: any, idStr: string) => {
    const data = {
        event: 'detailBeforeEntryEv',
        get_key: `stamp_before_list_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvDetailEndBeforeEntry = (str: any, idStr: string) => {
    const data = {
        event: 'detailEndBeforeEntryEv',
        get_key: `stamp_before_list_end_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvDetailEndAfterEntry = (str: any, idStr: string) => {
    const data = {
        event: 'detailEndAfterEntryEv',
        get_key: `stamp_after_list_end_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvDetailBeforeClickFaqBtn = (idStr: string) => {
    const data = {
        event: 'detailBeforeFaqClickEv',
        get_key: `stamp_before_faq`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvDetailBeforeClickPrevBtn = (idStr: string) => {
    const data = {
        event: 'detailBeforePrevClickEv',
        get_key: `stamp_before_back`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickDetailBeforeFloatBtn = (idStr: string) => {
    const data = {
        event: 'detailBeforeFloatBtnClickEv',
        get_key: `stamp_before_floating`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvDetailBeforeEntryClick = (str: any, idStr: string) => {
    const data = {
        event: 'detailBeforeEntryClick',
        get_key: `stamp_before_entry_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvDetailAfterView = (str: any, idStr: string) => {
    const data = {
        event: 'detailAfterViewEv',
        get_key: `stamp_after_list_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvDetailAfterClickFaqBtn = (idStr: string) => {
    const data = {
        event: 'detailAfterFaqClickEv',
        get_key: `stamp_after_faq`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvDetailAfterClickPrevBtn = (idStr: string) => {
    const data = {
        event: 'detailAfterPrevClickEv',
        get_key: `stamp_after_back`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvDetailAfterHistoryClick = (str: any, idStr: string) => {
    const data = {
        event: 'detailAfterHistoryClick',
        get_key: `apphistory_list_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvDetailAfterAppClick = (str: any, idStr: string) => {
    const data = {
        event: 'detailAfterAppClick',
        get_key: `stamp_after_apphistory_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickDetailAfterFloatBtn = (idStr: string) => {
    const data = {
        event: 'detailAfterFloatBtnClickEv',
        get_key: `stamp_after_floating`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvViewAppHistory = (str: any, idStr: string) => {
    const data = {
        event: 'appHistorykEvView',
        get_key: `apphistory_list_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickAppHistoryClose = (idStr: string) => {
    const data = {
        event: 'appHistoryClosekEvClick',
        get_key: `apphistory_close`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvViewAppConfirm = (str: any, idStr: string) => {
    const data = {
        event: 'appConfirmClick',
        get_key: `appverify_list_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvAppConfirmClickFaqBtn = (idStr: string) => {
    const data = {
        event: 'appConfirmClickFaqClickEv',
        get_key: `appverify_faq`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvAppConfirmClickAppBtn = (str: any, idStr: string) => {
    const data = {
        event: 'appConfirmClickAppClickEv',
        get_key: `appverify_app_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvAppConfirmClickPrevBtn = (idStr: string) => {
    const data = {
        event: 'appverifyStamp',
        get_key: `appverify_stamp`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickAppConfirmFloatBtn = (idStr: string) => {
    const data = {
        event: 'appConfirmFloatBtnClickEv',
        get_key: `appverify_floating`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvViewAppConfirmComplete = (str: any, idStr: string) => {
    const data = {
        event: 'appConfirmCompleteViewEv',
        get_key: `appcomp_list_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvAppConfirmComplete = (str: any, idStr: string) => {
    const data = {
        event: 'appConfirmCompleteEv',
        get_key: `appverify_app_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickAppConfirmCompleteFaqBtn = (idStr: string) => {
    const data = {
        event: 'appConfirmCompleteFaqClickEv',
        get_key: `appcomp_faq`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickAppConfirmCompletePrevBtn = (idStr: string) => {
    const data = {
        event: 'appcomp_stampcard',
        get_key: `appcomp_stampcard`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickAppConfirmStampListBackBtn = (idStr: string) => {
    const data = {
        event: 'appcomp_stamp',
        get_key: `appcomp_stamp`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickAppConfirmCompleteFloatBtn = (idStr: string) => {
    const data = {
        event: 'appConfirmCompletePrevClickEv',
        get_key: `appcomp_floating`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvViewError = (str: any, idStr: string) => {
    const data = {
        event: 'ErrorViewEv',
        get_key: `error_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvViewCampaignItem = (str: any, idStr: string) => {
    const data = {
        event: 'stampItemViewEv',
        get_key: `campaign_item_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};