import React, { useState } from 'react';

import { format, isBefore, isAfter, add, isEqual } from 'date-fns';

const Stamp:React.FC<{getStampProp:any}> = ({getStampProp}) => {

    if(getStampProp){
        return (
            <div className='stamp text-center'>
                <div className='px-3 py-2'><img src={`${process.env.REACT_APP_IMG_URL}${getStampProp.banner_url}`} className='w-full'/></div>
                {/* <div className='stampDatetime'>{format(new Date(getStampProp.stamp_give_datetime), 'MM/dd')}</div> */}
                <div className='stampDatetime'>
                    {
                        (() => {
                            if(getStampProp.trad_datetime){
                                return format(new Date(getStampProp.trad_datetime.replace(' ', 'T')), 'MM/dd')
                            }else{
                                return format(new Date(getStampProp.stamp_give_datetime.replace(' ', 'T')), 'MM/dd')
                            }
                        })()
                    }
                </div>
            </div>
        );
    }else{
        return (
            <div className='stamp text-center'>
                <div className='px-3 py-2'><img src="img/noting_stamp.png" className='w-full'/></div>
                <div className='stampDatetime'>&nbsp;</div>
            </div>
        );
    }
}

export default Stamp;