import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import axios from "axios";

import { useSelector, AppDispatch } from '../../store';

import { campaignListData, campaignListData2} from '../../../type/stamp_props';

import { setChangeView, setError } from '../../slice/viewSlice';

import Campaign from './Campaign'

const ListWrap:React.FC = () => {
    const dispatch:AppDispatch = useDispatch();
    const auth:any = useSelector((state) => state.authentication);
    const [dataState, setDataState] = useState<campaignListData2[]>([]);
    const [loadState, setLoadState] = useState(false);
    const errorState:any = useSelector((state) => state.viewData.errorState);
    const viewState:Number = useSelector((state) => state.viewData.viewState);

    useEffect(() => {
        const getCampaigns = async (): Promise<void> => {
            const headers:any = {
                'Content-Type':'application/json',
                'Cache-Control': 'no-cache',
                'Authorization':auth.data.res_data.token
            };

            await axios.request({
                url:`${process.env.REACT_APP_API_URL}api/api0002`,
                method:'GET',
                headers:headers,
                params:{
                    "consumer_id": auth.data.res_data.consumer_id,
                    "retailer_id": auth.data.res_data.retailer_id
                }
            }).then((response) =>{
                setDataState(response.data.res_data.campaign_list);
                setLoadState(true);
            }).catch((error) =>{
                console.log(error);
                dispatch(setChangeView({viewState:5, prevViewState: viewState}));
                dispatch(setError({
                    errorState:1,
                    errorDetail:error.response.data.error_info.error_details,
                    errorViewId:'screen_before_stamp'
                }));
                setLoadState(true);
            });
        };
        
        getCampaigns();
        window.scrollTo(0, 0);
    },[]);

    if(loadState){
        if(dataState.length !== 0){
            return (
                <div className='campaginListWrap p-6'>
                    {dataState.map((data:campaignListData2, index) => {
                        return <Campaign campaignListDataProp={data} key={index}/>
                    })}
                </div>
            );
        }else{
            return (
                <div className='flex items-center justify-center errorMain'>
                    <div>
                        <p>キャンペーンの登録がございません。</p>
                    </div>
                </div>
            );
        }
    }else{
        return(
            <div className='flex justify-center items-center h-screen'>
                <div className='w-1/3'>
                    <img src="img/spinner.gif" className='w-full'/>
                    <div className='readTxt'>読み込み中</div>
                </div>
            </div>
        );
    }
}

export default ListWrap;