import { PayloadAction } from '@reduxjs/toolkit';

import {
    PagePreviewBaseState,
    PagePreviewState,
    ChangePagePreviewState,
    PagePreviewErrorState
} from '../../../type/preview_props'

export const previewFunc = (state:PagePreviewBaseState, action:PayloadAction<PagePreviewState>) => {
    state.viewState = action.payload.viewState;
    state.detailId = action.payload.detailId;
    state.userIdState = action.payload.userIdState;
    state.tokenState = action.payload.tokenState;
};

export const changePreviewFunc = (state:PagePreviewBaseState, action:PayloadAction<ChangePagePreviewState>) => {
    state.viewState = action.payload.viewState;
    state.detailId = action.payload.detailId;
};

export const previewErrorFunc = (state:PagePreviewBaseState, action:PayloadAction<PagePreviewErrorState>) => {
    state.errorState = action.payload.errorState;
    state.errorDetail = action.payload.errorDetail;
};