import React, { useEffect, useState } from 'react';
import { useSelector } from '../../store';

import {
    reactTagManagerEvViewError
} from '../../../functions/function';

const ErrorAll:React.FC = () => {
    // const viewState:any = useSelector((state) => state.viewData);
    const previewState:any = useSelector((state) => state.previewViewData);
    // const auth:any = useSelector((state) => state.authentication);
    // console.log(auth);

    useEffect(() => {
        // if('res_data' in auth.data){
        //     reactTagManagerEvViewError(viewState.errorDetail, auth.data.res_data.send_id);
        // }else{
        //     reactTagManagerEvViewError(viewState.errorDetail, 'null');
        // }
        const storage = localStorage;
        storage.clear();
    },[]);

    const returnLogin = () => {
        const url:URL = new URL(window.location.href);
        const params:URLSearchParams = url.searchParams;
        const campaignIdParams:any = params.get('id');
        if(campaignIdParams == null || campaignIdParams == ''){
            window.location.href = '/?preview=true';
        }else{
            window.location.href = '/?preview=true&id=' + campaignIdParams;
        }
    };

    return(
        <div className='flex items-center justify-center errorMain'>
            <div>
                <div className='w-3/6 mx-auto'><img src="img/error.png" className='w-full' /></div>
                <h2 className='text-center mt-6'>エラー</h2>
                <div className='text-center mt-3'>
                    <p>正常に処理できませんでした。</p>
                    <p>再度お試しください。</p>
                    <p>{previewState.errorDetail}</p>
                    <div id='input'>
                        <div id='submit' onClick={returnLogin}>ログイン画面へ</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ErrorAll;