import React from 'react';

import * as Scroll from 'react-scroll';
import { animateScroll as scroll } from 'react-scroll';
import { format } from 'date-fns';

import { useSelector } from '../store';

import {
    isAfterEqual,
    reactTagManagerEvClickCampaignFloatBtn,
    reactTagManagerEvClickDetailBeforeFloatBtn,
    reactTagManagerEvClickDetailAfterFloatBtn,
    reactTagManagerEvClickAppConfirmFloatBtn,
    reactTagManagerEvClickAppConfirmCompleteFloatBtn
} from '../../functions/function';

const UpIcon:React.FC = () => {
    const auth:any = useSelector((state) => state.authentication);
    const viewState:Number = useSelector((state) => state.viewData.viewState);
    const campaignData:any = useSelector((state) => state.campaignData);
    const confirmState:Number = useSelector((state) => state.viewData.confirmState);

    const scrollToTop = () => {
        if(viewState === 0){
            reactTagManagerEvClickCampaignFloatBtn(auth.data.res_data.send_id);
        }else if(viewState === 1 && !campaignData.is_entry){
            reactTagManagerEvClickDetailBeforeFloatBtn(auth.data.res_data.send_id);
        }else if(viewState === 1 && campaignData.is_entry){
            reactTagManagerEvClickDetailAfterFloatBtn(auth.data.res_data.send_id);
        }else if(viewState === 2 && confirmState !== 2){
            reactTagManagerEvClickAppConfirmFloatBtn(auth.data.res_data.send_id);
        }else if(viewState === 2 && confirmState === 2){
            reactTagManagerEvClickAppConfirmCompleteFloatBtn(auth.data.res_data.send_id);
        }
        scroll.scrollToTop();
    };

    return (
        <div className='upIcon' onClick={scrollToTop}>
            <img src="img/icon_angle_up.png" />
        </div>
    );
}

export default UpIcon;