import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import axios from "axios";

import { AppDispatch } from '../../../component/store'

import { useSelector } from '../../store';

import {
    setPreview,
    setPreviewError,
} from '../../slice/preview/previewSlice';


const LoginInput:React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const [userIdState, setUserIdState] = useState<string>('');
    const [userPasswordState, setUserPasswordState] = useState<string>('');
    const [campaignIdParamState, setCampaignIdParamState] = useState<Number>(0);

    useEffect(() => {
        const url:URL = new URL(window.location.href);
        const params:URLSearchParams = url.searchParams;
        const campaignIdParams:any = params.get('id');
        setCampaignIdParamState(campaignIdParams);
    },[]);

    const loginSubmit = async () => {
        try {
            const headers:any = {
                'Content-Type':'application/json',
                'Cache-Control': 'no-cache',
            };

            const response:any = await axios.request({
                url:`${process.env.REACT_APP_API_URL}api/preview_api0001`,
                method:'POST',
                headers:headers,
                params:{
                    "id": userIdState,
                    "password": userPasswordState
                }
            });

            let resData = response.data;

            const storage = localStorage;
            storage.setItem('token', resData.res_data.token);
            storage.setItem('userid', userIdState);
            dispatch(setPreview({
                viewState: 1,
                detailId: campaignIdParamState,
                tokenState: resData.res_data.token,
                userIdState: userIdState
            }));
        }catch (e:any) {
            console.log(e);
            if(e.response.statusText){
                dispatch(setPreview({
                    viewState: 2,
                    detailId: 0,
                    tokenState: '',
                    userIdState: ''
                }));
                dispatch(setPreviewError({
                    errorState:1,
                    errorDetail:e.response.data.error_info.error_details
                }));
                return {
                    status: e.response.statusText,
                    data: e,
                };
            }else{
                dispatch(setPreview({
                    viewState: 2,
                    detailId: 0,
                    tokenState: '',
                    userIdState: ''
                }));
                dispatch(setPreviewError({
                    errorState:1,
                    errorDetail:e.response.data.error_info.error_details
                }));
                return {
                    status: 'failed',
                    data: e,
                };
            }
        }
    };

    return (
        <div id='loginWrap'>
            <h1>プレビュー認証画面</h1>
            <div id='input'>
                <div>
                    <label htmlFor="userId">userId</label>
                    <input type="text" name="userId" id="userId" value={userIdState} onChange={(e)=>setUserIdState(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="userPassword">userPassword</label>
                    <input type="password" name="userPassword" id="userPassword" value={userPasswordState} onChange={(e)=>setUserPasswordState(e.target.value)} />
                </div>
                <button id='submit' onClick={loginSubmit}>認証</button>
            </div>
        </div>
    )
}

export default LoginInput;