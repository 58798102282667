import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppConditionItemState } from '../../../type/stamp_props'

import { setAppConditionItemFunc } from './application_condition'


const applicationConditionSlice = createSlice({
    name: 'applicationConditionData',
    initialState: {
        application_condition:'',
        banner_url:'',
        application_condition_id:0,
        is_apply:false,
        is_entry:false,
        num_stamp_require_app:0,
        num_gift_point:0,
        remaining_stamp:0,
        is_later:0
    },
    reducers: {
        setAppConditionItem: {
            reducer: setAppConditionItemFunc,
            prepare(payload: AppConditionItemState){
                return {payload}
            }
        }
    },
});

export const { setAppConditionItem } = applicationConditionSlice.actions;
export default applicationConditionSlice.reducer;