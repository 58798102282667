import React, { useState ,useEffect} from 'react';
import { useDispatch } from 'react-redux';

import BackList from './BackList';
import StampCardArea from './StampCardArea';
import HistoryWrap from './HistoryWrap';
import ErrorDialog from '../error/ErrorDialog';

import { setHistoryView } from '../../slice/viewSlice';
import { useSelector, AppDispatch } from '../../store';

import _ from 'lodash';
import { format, isBefore, isAfter, add, isEqual, parseISO } from 'date-fns';
import {
    isBeforeEqual,
    isAfterEqual,
    reactTagManagerEvDetailBeforeEntry,
    reactTagManagerEvDetailEndBeforeEntry,
    reactTagManagerEvDetailAfterView,
    reactTagManagerEvDetailEndAfterEntry,
    reactTagManagerEvDetailBeforeEntryClick
} from '../../../functions/function';

import axios from "axios";

import { setError,setChangeView } from '../../slice/viewSlice';
import { setCampaignView, setCampaignIsEntry } from '../../slice/campaign/campaignSlice';
import { doesNotMatch } from 'assert';

const DetailWrap:React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const auth:any = useSelector((state) => state.authentication);
    const campaignId:any = useSelector((state) => state.viewData.detailId);
    const campaignData:any = useSelector((state) => state.campaignData);
    const viewState:Number = useSelector((state) => state.viewData.viewState);
    const isLimitedState:Number = useSelector((state) => state.viewData.isLimited);
    const errorState:any = useSelector((state) => state.viewData.errorState);
    const [HistoryDataState, setHistoryDataState] = useState({});
    const [entryState, setEntryState] = useState<Number>(0);
    const [onEntryState, setOnEntoryState] = useState(false);
    const [loadState, setLoadState] = useState(false);

    const HistoryState:Number = useSelector((state) => state.viewData.historyState);

    useEffect(() => {
        window.scrollTo(0, 0);

        dispatch(setHistoryView({historyState: 0}));

        const getCampaignDetail = async (): Promise<void> => {
            const headers:any = {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
                'Authorization':auth.data.res_data.token
            };

            try{
                const campaignResponse = await axios.request({
                    url:`${process.env.REACT_APP_API_URL}api/api0003`,
                    method:'GET',
                    headers:headers,
                    params:{
                        "consumer_id": auth.data.res_data.consumer_id,
                        "retailer_id": auth.data.res_data.retailer_id,
                        "campaign_id": campaignId,
                        "is_limited" : isLimitedState
                    }
                });

                const stampResponse = await axios.request({
                    url:`${process.env.REACT_APP_API_URL}api/api0006`,
                    method:'GET',
                    headers:headers,
                    params:{
                        "consumer_id": auth.data.res_data.consumer_id,
                        "retailer_id": auth.data.res_data.retailer_id,
                        "campaign_id": campaignId
                    }
                });

                setHistoryDataState(stampResponse.data.res_data);
                dispatch(setCampaignView(campaignResponse.data.res_data[0]));
                if(campaignResponse.data.res_data[0].is_entry){
                    setEntryState(2);
                }else{
                    setEntryState(0);
                }

                setLoadState(true);

            }catch(e:any){
                dispatch(setChangeView({viewState:5, prevViewState: viewState}));
                let errMsg = '';
                if('response' in e && e.response){
                    errMsg = e.response.data.error_info.error_details;
                }else{
                    errMsg = 'MSG-ERROR-ERR_NETWORK';
                }

                console.log(e);
                if('response' in e){
                    dispatch(setError({
                        errorState:1,
                        errorDetail:errMsg,
                        errorViewId:e.response.data.error_info.error_details
                    }));
                }else{
                    errMsg = 'MSG-ERROR-ERR_API';
                    dispatch(setError({
                        errorState:1,
                        errorDetail:errMsg,
                        errorViewId:'99'
                    }));
                }

                setLoadState(true);
            }
        };

        getCampaignDetail();
    },[]);

    const entryBtn = async () => {
        setEntryState(1);
        reactTagManagerEvDetailBeforeEntryClick(campaignId, auth.data.res_data.send_id);
        const headers:any = {
            'Content-Type':'application/x-www-form-urlencoded',
            'Cache-Control': 'no-cache',
            'Authorization':auth.data.res_data.token
        };
        await axios.request({
            url:`${process.env.REACT_APP_API_URL}api/api0004`,
            method:'POST',
            headers:headers,
            data:{
                "consumer_id": auth.data.res_data.consumer_id,
                "retailer_id": auth.data.res_data.retailer_id,
                "campaign_id": campaignId,
                "is_limited" : isLimitedState
            }
        }).then((response) => {
            setEntryState(2);
            setOnEntoryState(true);

            setTimeout(() => {
                setOnEntoryState(false);
            }, 6000);

            dispatch(setCampaignIsEntry({is_entry:true}));

            return response;
        }).catch((error) => {
            if(error.message){
                dispatch(setError({
                    errorState:1,
                    errorDetail:error.message,
                    errorViewId:'screen_before_stamp'
                }));
            }
            dispatch(setError({
                errorState:1,
                errorDetail:'MSG-ERROR-NETWORK000001',
                errorViewId:'screen_before_stamp'
            }));
        });
    };

    const today = new Date(format(new Date(), 'yyyy/MM/dd'));
    const newFlag = isBeforeEqual(today,add(new Date(campaignData.target_period_start.replace(' ', 'T')),{days: 7}));

    if(loadState){
        if(campaignData.campaign_id !== 0){
            if(!isAfter(today,parseISO(campaignData.app_period_end))){
                if(entryState === 2){
                    reactTagManagerEvDetailAfterView(campaignId, auth.data.res_data.send_id);
                }else{
                    reactTagManagerEvDetailBeforeEntry(campaignId, auth.data.res_data.send_id);
                }
            }else {
                if(entryState === 2){
                    reactTagManagerEvDetailEndAfterEntry(campaignId, auth.data.res_data.send_id);
                }else{
                    reactTagManagerEvDetailEndBeforeEntry(campaignId, auth.data.res_data.send_id);
                }
            }

            return (
                <>
                    {HistoryState === 1 ? <HistoryWrap historyObj={HistoryDataState} />: <></>}
                    <BackList />

                    <div className='px-6 pb-24'>
                        <section className='detailFirstArea'>
                            <div className='flex campaignStatusTxt mb-3'>
                                {newFlag ? <div className='newTag'>NEW</div> : <></>}
                                {campaignData.hold_status === 1 ? <span className='detailStatus'>開催中</span> : <></>}
                                {campaignData.hold_status === 2 ? <span className='detailStatus'>まもなく終了</span> : <></>}
                                {campaignData.hold_status === 3 ? <span className='detailStatus'>応募受付中</span> : <></>}
                                {campaignData.hold_status === 4 ? <span className='detailStatus detailStatusEnd'>終了</span> : <></>}
                            </div>
                            <figure>
                                {
                                    (() => {
                                        if(campaignData.banner_url){
                                            return <div className='campaignImg'><img src={`${process.env.REACT_APP_IMG_URL}${campaignData.banner_url}`} className='w-full'/></div>
                                        }else{
                                            return <img src="img/dummy1.png" className='w-full'/>
                                        }
                                    })()
                                }
                            </figure>
                            <h2 className='my-4'>{campaignData.title}</h2>
                        </section>
                        {entryState === 2 ? <StampCardArea />: <></>}
                        <section className='detailSecondArea'>
                            <div className='p-2'>
                                <h3 className='mb-1'>対象期間</h3>
                                <div className='px-2'><span>{format(new Date(campaignData.target_period_start.replace(' ', 'T')), 'yyyy/MM/dd')}</span><span>-</span><span>{format(new Date(campaignData.target_period_end.replace(' ', 'T')), 'yyyy/MM/dd')}</span></div>
                            </div>
                            <div className='p-2'>
                                <h3 className='mb-1'>景品応募対象期間</h3>
                                <div className='px-2'><span>{format(new Date(campaignData.app_period_start.replace(' ', 'T')), 'yyyy/MM/dd')}</span><span>-</span><span>{format(new Date(campaignData.app_period_end.replace(' ', 'T')), 'yyyy/MM/dd')}</span></div>
                            </div>
                            <div className='p-2'>
                                <h3 className='mb-1'>スタンプ条件</h3>
                                <div className='px-2 detailSecondAreaString' dangerouslySetInnerHTML={{__html:campaignData.stamp_condition}}></div>
                            </div>
                            <div className='p-2'>
                                <h3 className='mb-1'>対象店舗</h3>
                                <div className='px-2 detailSecondAreaString' dangerouslySetInnerHTML={{__html:campaignData.target_store}}></div>
                            </div>
                            <div className='p-2'>
                                <h3 className='mb-1'>景品</h3>
                                <div className='px-2 detailSecondAreaString' dangerouslySetInnerHTML={{__html:campaignData.freebie_content}}></div>
                            </div>
                        </section>
                        <section className='precaution p-6 my-8'>
                            <h2 className='pb-3'>ご利用上のご注意</h2>
                            <div dangerouslySetInnerHTML={{__html:campaignData.usage_notice}}></div>
                        </section>
                    </div>
                    {
                        (() => {
                            if(entryState !== 2){
                                if(entryState === 1){
                                    return (<nav className='entryBtnWrap p-6 fixed bottom-0 right-0 w-full disable'>
                                                    <div className='text-center p-5'>エントリー処理中</div>
                                            </nav>)
                                }

                                if(campaignData.hold_status === 4 || campaignData.hold_status === 3){
                                    return (<nav className='entryBtnWrap p-6 fixed bottom-0 right-0 w-full disable'>
                                                <div className='text-center p-5'>上記確認のうえエントリー</div>
                                            </nav>)
                                }else{
                                    return (<nav className='entryBtnWrap p-6 fixed bottom-0 right-0 w-full'>
                                                <div className='text-center p-5' onClick={entryBtn}>上記確認のうえエントリー</div>
                                            </nav>)
                                }
                            }
                        })()
                    }
                    {onEntryState ? <div className='set-information'><div className='infomationTxt'>スタンプ対象商品をご購入の際、ウエルシアグループアプリで表示するWAON POINTのバーコード、もしくはウエルシアグループアプリに登録済のWAON POINTがたまるカード(アプリTOPから確認可能)をご提示ください</div></div>: <></>}
                    {errorState === 1 ? <ErrorDialog errorProp={0}/> : <></>}
                </>
            );
        }else{
            return<></>;
        }
    }else{
        return(
            <div className='flex justify-center items-center h-screen'>
                <div className='w-1/3'>
                    <img src="img/spinner.gif" className='w-full'/>
                    <div className='readTxt'>読み込み中</div>
                </div>
            </div>
        );
    }
}

export default DetailWrap;