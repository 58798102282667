import React from 'react';
import { useDispatch } from 'react-redux';

import { useSelector, AppDispatch } from './store';
import Back from './main/Back'

import { setChangeView } from './slice/viewSlice';

import { format } from 'date-fns';
import {
    isAfterEqual,
    reactTagManagerEvCampaignClickFaqBtn,
    reactTagManagerEvDetailBeforeClickFaqBtn,
    reactTagManagerEvDetailAfterClickFaqBtn,
    reactTagManagerEvAppConfirmClickFaqBtn,
    reactTagManagerEvClickAppConfirmCompleteFaqBtn
} from '../functions/function';
import { reloadIframe } from './main/Faq'
import { norticeReloadIframe } from './main/Notice';

const Header:React.FC = () => {
    const dispatch:AppDispatch = useDispatch();
    const auth:any = useSelector((state) => state.authentication);
    const viewState:Number = useSelector((state) => state.viewData.viewState);
    const campaignData:any = useSelector((state) => state.campaignData);
    const confirmState:Number = useSelector((state) => state.viewData.confirmState);

    const displayStartDate = new Date('2024-08-07T11:00:00')
    const displayEndDate = new Date('2024-08-23T23:59:59');

    const displayStampMessageEndDate = new Date('2024-08-23T23:59:59');
    const displayMaintenanceMessageEndDate = new Date('2024-08-22T03:00:00');

    const now = new Date();
    const today = new Date(format(new Date(), 'yyyy/MM/dd'));

    const returnBtnClick = () => {
        dispatch(setChangeView({viewState:0, prevViewState: viewState}));
    };

    const displayNotice = displayStartDate <= now && now <= displayEndDate;
    const displayStampMessageNotice = displayStartDate <= now && now <= displayStampMessageEndDate;
    const displayMaintenanceMessageNotice = displayStartDate <= now && now <= displayMaintenanceMessageEndDate;

    const faqClick = () => {
        if(viewState === 0){
            reactTagManagerEvCampaignClickFaqBtn(auth.data.res_data.send_id);
        }else if(viewState === 1 && !campaignData.is_entry && !isAfterEqual(today,campaignData.app_period_end)){
            reactTagManagerEvDetailBeforeClickFaqBtn(auth.data.res_data.send_id);
        }else if(viewState === 1 && campaignData.is_entry){
            reactTagManagerEvDetailAfterClickFaqBtn(auth.data.res_data.send_id);
        }else if(viewState === 2 && confirmState !== 2){
            reactTagManagerEvAppConfirmClickFaqBtn(auth.data.res_data.send_id);
        }else if(viewState === 2 && confirmState === 2){
            reactTagManagerEvClickAppConfirmCompleteFaqBtn(auth.data.res_data.send_id);
        }

        if(viewState !== 3){
            dispatch(setChangeView({viewState:3, prevViewState: viewState}));
        } else {
            reloadIframe();
        }
    };

    const handleLinkClick = () => {
        if(viewState === 0){
            reactTagManagerEvCampaignClickFaqBtn(auth.data.res_data.send_id);
        }else if(viewState === 1 && !campaignData.is_entry && !isAfterEqual(today,campaignData.app_period_end)){
            reactTagManagerEvDetailBeforeClickFaqBtn(auth.data.res_data.send_id);
        }else if(viewState === 1 && campaignData.is_entry){
            reactTagManagerEvDetailAfterClickFaqBtn(auth.data.res_data.send_id);
        }else if(viewState === 2 && confirmState !== 2){
            reactTagManagerEvAppConfirmClickFaqBtn(auth.data.res_data.send_id);
        }else if(viewState === 2 && confirmState === 2){
            reactTagManagerEvClickAppConfirmCompleteFaqBtn(auth.data.res_data.send_id);
        }

        if(viewState !== 6){
            dispatch(setChangeView({viewState:6, prevViewState: viewState}));
        } else {
            norticeReloadIframe();
        }
    };

    if(auth.status !== 'loading' && auth.data.res_code === 200){
        return (
            <header>
                <div className='flex mx-auto items-center headerWrap'>
                    <div className='topLogoImg'>
                        <img src={`${process.env.REACT_APP_IMG_URL}${auth.data.res_data.banner_url}`} className='w-full'/>
                    </div>
                    <h1 onClick={returnBtnClick}>WAON POINT スタンプ</h1>
                    <div className='topQuestionImg'>
                        <img src='img/question.png' className='w-full' onClick={faqClick}/>
                    </div>
                    <br/>
                </div>
                {displayNotice && (
                    <div className='maintenance-notice'>
                        {displayStampMessageNotice && (
                            <div className="notice">
                                <p className="notice-title">
                                    ●スタンプの進呈が遅れています
                                </p>
                                <p className="notice-body">
                                    WAON POINTスタンプの進呈が数日程度遅れが発生しております　<button onClick={handleLinkClick} className="link-button url">詳細はこちら</button>
                                </p>
                            </div>
                        )}
                        {displayMaintenanceMessageNotice && (
                            <div className="notice"><br/>
                                <p className="notice-title">
                                    ●サーバメンテナンスのお知らせ
                                </p>
                                <p className="notice-body">
                                    期間2024年8月22日(木)AM1:00-3:00まで
                                </p>
                                <p className="notice-body">
                                    ※メンテナンス中はスタンプサイトにアクセスできませんのでご了承ください
                                </p>
                            </div>
                        )}
                    </div>
                )}
                {viewState === 3 || viewState === 6? <Back /> : <></>}
            </header>
        );
    }else{
        return <></>;
    }
}

export default Header;