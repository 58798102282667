import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";

import { ApiResponse } from '../../../type/stamp_props'

export const history = createAsyncThunk<ApiResponse, { consumer_id:string, retailer_id:string, campaign_id:string, token:string }>(
    'history',
    async ({consumer_id, retailer_id, campaign_id, token}) => {
        try {
            const headers:any = {
                'Content-Type':'application/json',
                'Cache-Control': 'no-cache',
                'Authorization':token
                // 'Ocp-Apim-Subscription-Key': (window as any)._env_.REACT_APP_OCP_API_SUBSCRIPTION_KEY
            };
            const historyResponse = await axios.request({
                // url: (window as any)._env_.REACT_APP_LOGIN_URL,
                url:`${process.env.REACT_APP_API_URL}api/api0006`,
                method:'GET',
                headers:headers,
                params:{
                    "consumer_id": consumer_id,
                    "retailer_id": retailer_id,
                    "campaign_id": campaign_id
                }
            });

            // console.log(historyResponse);

            return  historyResponse.data;
        }catch (e:any) {
            console.log(e);
            if(e.response.statusText){
                return {
                    status: e.response.statusText,
                    data: e,
                };
            }else{
                return {
                    status: 'failed',
                    data: e,
                };
            }
        }
    }
);