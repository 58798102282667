import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CampaignItemState, CampaignIsEntryState } from '../../../type/stamp_props'

import { setCampaignItemFunc, setCampaignIsEntryFunc } from './campaign'


const campaignSlice = createSlice({
    name: 'campaignData',
    initialState: {
        app_condition_list:[],
        app_period_end:'',
        app_period_start:'',
        banner_url:'',
        campaign_id:0,
        disp_period_end:'',
        disp_period_start:'',
        freebie_content:'',
        hold_status:0,
        is_entry:false,
        //is_later:false,
        is_limited:false,
        point_limit:0,
        total_point:0,
        sheet_number:0,
        stamp_condition:'',
        stamp_list:[],
        stamp_number:0,
        target_period_end:'',
        target_period_start:'',
        target_prod:'',
        target_store:'',
        title:'',
        total_stamp:0,
        unconsumed_stamp:0,
        usage_notice:''
    },
    reducers: {
        setCampaignView: {
            reducer: setCampaignItemFunc,
            prepare(payload: CampaignItemState){
                return {payload}
            }
        },
        setCampaignIsEntry: {
            reducer: setCampaignIsEntryFunc,
            prepare(payload: CampaignIsEntryState){
                return {payload}
            }
        }
    },
});

export const { setCampaignView, setCampaignIsEntry } = campaignSlice.actions;
export default campaignSlice.reducer;