import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { history } from './history';

export const historySlice = createSlice({
    name: 'history',
    initialState: {
        status: 'loading',
        data:{}
    },
    reducers: {
        getHistory: (state) => {
            state.data = { status: 'idle', data: [] };
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(history.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(history.fulfilled, (state, action) => {
            state.status = 'idle';
            state.data = action.payload;
        });
    },
});

export const { getHistory } = historySlice.actions;
export default historySlice.reducer;