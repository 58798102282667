import React, { useState } from 'react';

import Stamp from './Stamp';
import { useSelector, AppDispatch } from '../../store';

import {sliceByNumber} from '../../../functions/function';

const StampCard:React.FC = () => {
    const campaignData:any = useSelector((state) => state.campaignData);
    const stampListLength = campaignData.stamp_list.length;

    let stampItems:any[] = sliceByNumber([...campaignData.stamp_list], 20);

    if(stampItems.length !== 0){
        stampItems.map((item, index) => {
            const stampListLength = stampItems[index].length;
            if(stampItems[index].length <= 20){
                for(let i = 0; i < 20 - stampListLength; i++){
                    stampItems[index].push(false);
                }
            }
        });
    }else{
        stampItems[0] = [];
        for(let i = 0; i < 20; i++){
            stampItems[0].push(false);
        }
    }

    const viewStampCardStamps:any[] = stampItems.pop();

    return (
        <>
            <section className='stampcardWrap my-3 px-6 pt-3 pb-0'>
                <h3 className='stampCardTitle text-center mb-3'>スタンプカード</h3>
                <div className='stampArea p-3'>
                    <div className='grid grid-cols-5'>
                        {viewStampCardStamps.map((item:any, idx) => <Stamp getStampProp={item} key={idx}/>)}
                    </div>
                </div>
                <div className='flex cardCntArea py-3'>
                    <div className='w-3/6 text-center'>
                        スタンプ:<span className='stampCardNum'>{stampListLength}</span>個
                    </div>
                    <div className='w-3/6 text-center'>
                        カード:<span className='stampCardNum'>{campaignData.sheet_number}</span>枚目
                    </div>
                </div>
            </section>
            <section className='stampSortTxt'>※スタンプ進呈順に並んでおります。表示された日付はお買い物された日付です。</section>
        </>
    );
}

export default StampCard;