import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import axios from "axios";
import { format, isBefore, isAfter, add, isEqual } from 'date-fns';

import { useSelector } from '../../store';

import { isBeforeEqual, isAfterEqual } from '../../../functions/function';

import { setPreview, setPreviewError } from '../../slice/preview/previewSlice';

const PreviewAppParts:React.FC<{appConditionData:any}> = ({appConditionData}) => {

    return (
        <section className='px-6 confirmWrap'>
            <div className='py-6 text-center confirmTitle'>
                <h2 className=''>応募内容の確認</h2>
                <div className='ConfirmTitleSub'>
                    <p>以下の景品に応募しますか？</p>
                </div>
            </div>
            <div>
                {
                    (() => {
                        if (appConditionData.banner_url) {
                            return <div><img src={`${process.env.REACT_APP_IMG_URL}${appConditionData.banner_url}`} className='w-full' /></div>
                        } else {
                            return <div><img src="img/dummy1.png" className='w-full' /></div>
                        }
                    })()
                }
                <div className='p-5 conrfirmTxt'>
                    <div dangerouslySetInnerHTML={{__html:appConditionData.application_condition}}></div>
                </div>
            </div>
            <div className='mt-6'>
                <div className='text-center confirmAfterStamp'>消費スタンプ：<span>{appConditionData.num_stamp_require_app}</span>スタンプ</div>
                <div className='text-center confirmAfterStamp'>(保有スタンプ数：<span>○○</span>スタンプ)</div>
            </div>
            <div className='mt-3'>
                <div className='confirmBtn text-center p-5'>応募する</div>
                <div className='confirmBackBtn text-center p-5 mt-3'>スタンプ一覧へ戻る</div>
            </div>
        </section>
    )

}

export default PreviewAppParts;