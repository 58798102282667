import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { authentication } from './authentication';

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: {
        status: 'waiting',
        data:{}
    },
    reducers: {
        getAuth: (state) => {
            state.data = { status: 'idle', data: [] };
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(authentication.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(authentication.fulfilled, (state, action) => {
            state.status = 'idle';
            state.data = action.payload;
        })
        .addCase(authentication.rejected, (state, action) => {
            state.status = 'idle';
            state.data = {};
        });;
    },
});

export const { getAuth } = authenticationSlice.actions;
export default authenticationSlice.reducer;