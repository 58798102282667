import React, { useState ,useEffect} from 'react';
import { useDispatch } from 'react-redux';

import { useSelector, AppDispatch } from '../../store'

import { setChangeView } from '../../slice/viewSlice';
import { setAppConditionItem } from '../../slice/application_condition/applicationConditionSlice';

import { format, isBefore, isAfter, add, isEqual, parseISO } from 'date-fns';

import {
    reactTagManagerEvDetailAfterAppClick
} from '../../../functions/function';

const ApplyBtn:React.FC<{applicationConditionData:any}> = ({applicationConditionData}) => {
    const dispatch: AppDispatch = useDispatch();
    const campaignId:any = useSelector((state) => state.viewData.detailId);
    const auth:any = useSelector((state) => state.authentication);
    const campaignData:any = useSelector((state) => state.campaignData);
    const viewState:Number = useSelector((state) => state.viewData.viewState);

    const confirmView = () => {
        reactTagManagerEvDetailAfterAppClick(campaignId, auth.data.res_data.send_id);
        dispatch(setAppConditionItem(applicationConditionData));
        dispatch(setChangeView({viewState: 2, prevViewState: viewState}));
    };

    const today = new Date(format(new Date(), 'yyyy/MM/dd'));

    if(applicationConditionData.remaining_stamp <= 0){
        return (
            <>
                {
                    (() => {
                        if(isAfter(today,parseISO(campaignData.app_period_end))){
                            return <div className='mt-5 px-3 text-center disable'><div className='py-3 applyBtn'>応募する</div></div>
                        }else{
                            return <div className='mt-5 px-3 text-center' onClick={confirmView}><div className='py-3 applyBtn'>応募する</div></div>
                        }
                    })()
                }
            </>
        );
    }else{
        return (
            <div className='mt-5 px-3 pt-5 text-center afterStampWrap'>
                <div className='afterStamp'>あと<span className='px-2'>{applicationConditionData.remaining_stamp}</span>スタンプ</div>
            </div>
        );
    }
}

export default ApplyBtn;