import React, { useState } from 'react';

import HistoryBtn from './HistoryBtn';
import StampCard from './StampCard';
import Prize from './Prize';

import { useSelector, AppDispatch } from '../../store';

const StampCardArea:React.FC = () => {
    const campaignData:any = useSelector((state) => state.campaignData);

    return(
        <>
            <section className='mt-6 mb-5'>
                <div className='flex justify-between'>
                    {campaignData.is_entry ? <div className='flex'><div className='exEntry px-2 py-1'>エントリー済</div></div> : <></>}
                    <HistoryBtn />
                </div>
            </section>
            {campaignData.is_entry ? <StampCard /> : <></>}

            {campaignData.app_condition_list.map((item:any, index:number) => <Prize appConditionData={item} itemNum={index} key={index}/>)}
        </>
    );
}

export default StampCardArea;