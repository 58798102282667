import React, { useState ,useEffect} from 'react';
import { useDispatch } from 'react-redux';

import { useSelector, AppDispatch } from '../../store'



const ConfirmTitle:React.FC= () => {
    const confirmState:Number = useSelector((state) => state.viewData.confirmState);
    const appConditionData:any = useSelector((state) => state.applicationConditionData);

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    if(confirmState === 2){
        if(appConditionData.is_later === 2 || appConditionData.is_later === 3){
            return(
                <div className='py-6 text-center confirmTitle'>
                    <h2>応募完了</h2>
                    <div className='ConfirmTitleSubLeft'>
                        <p>スタンプの交換が完了しました。</p>
                        <p>景品への交換時期は、キャンペーン詳細でご確認ください。</p>
                    </div>
                </div>
            );
        }else{
            return(
                <div className='py-6 text-center confirmTitle'>
                    <h2>応募完了</h2>
                    <div className='ConfirmTitleSubLeft'>
                        <p>以下のポイント交換の応募が完了しました。</p>
                        <p>ポイントは、応募後2日以内に自動加算されます。</p>
                    </div>
                </div>
            );
        }
    }else{
        return(
            <div className='py-6 text-center confirmTitle'>
                <h2 className=''>応募内容の確認</h2>
                <div className='ConfirmTitleSub'>
                    <p>以下の景品に応募しますか？</p>
                </div>
            </div>
        );
    }
}

export default ConfirmTitle;