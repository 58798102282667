import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector, AppDispatch } from '../store';

export const iframeRef = React.createRef<HTMLIFrameElement>();

const UpIcon:React.FC = () => {
    const dispatch:AppDispatch = useDispatch();
    const viewState:Number = useSelector((state) => state.viewData.viewState);
    const campaignId:any = useSelector((state) => state.viewData.detailId);
    const errorState:any = useSelector((state) => state.viewData.errorState);
    const confirmState:Number = useSelector((state) => state.viewData.confirmState);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 1200);
    },[]);

    return (
        <>
            <div className='faqIframe'>
                <iframe ref={iframeRef} src="https://faq.waonpoint.jp/category/show/86?site_domain=default"/>
            </div>
        </>
    );
}

export const reloadIframe = () => {
    if (iframeRef.current) {
      iframeRef.current.src += '';
    }
};

export default UpIcon;