import React, { useState ,useEffect} from 'react';
import { useDispatch } from 'react-redux';

import { useSelector, AppDispatch } from '../../store';

import { setError } from '../../slice/viewSlice';

import {
    reactTagManagerEvViewError
} from '../../../functions/function';

const ErrorDialog:React.FC<{errorProp:Number}> = ({errorProp}) => {
    const dispatch: AppDispatch = useDispatch();
    const viewState:any = useSelector((state) => state.viewData);
    const auth:any = useSelector((state) => state.authentication);

    useEffect(() => {
        reactTagManagerEvViewError(viewState.errorViewId, auth.data.res_data.send_id);
    },[]);

    let displayString = 'エントリーに失敗しました。';
    const dialogOff = () => {
        dispatch(setError({
            errorState:0,
            errorDetail:'',
            errorViewId:''
        }));
    };

    if(errorProp === 1){
        displayString = '応募に失敗しました。';
    }

    return(
        <div className='errorDialogParent fixed w-full flex justify-center items-center'>
            <div className='errorDialogWrap w-10/12 text-center py-6'>
                <h3 className='mb-3'>エラー</h3>
                <div>
                    <p>{displayString}</p>
                    <p>{viewState.errorDetail}</p>
                </div>
                <div className='errorOkBtn py-3 w-6/12 mx-auto mt-6' onClick={ dialogOff }>OK</div>
            </div>
        </div>
    );
}

export default ErrorDialog;