import { PayloadAction } from '@reduxjs/toolkit';

import {
    PageBaseState,
    PageCampaignState,
    PageErrorState,
    PageViewState,
    PageConfirmState,
    PageHistoryState,
} from '../../type/stamp_props'

export const campaignViewFunc = (state:PageBaseState, action:PayloadAction<PageCampaignState>) => {
    state.viewState = action.payload.viewState;
    state.detailId = action.payload.detailId;
    state.isLimited = action.payload.isLimited;
    state.prevViewState = action.payload.prevViewState;
};

export const viewChangeFunc = (state:PageBaseState, action:PayloadAction<PageViewState>) => {
    state.viewState = action.payload.viewState;
    state.prevViewState = action.payload.prevViewState;
};

export const setHistoryFunc = (state:PageBaseState, action:PayloadAction<PageHistoryState>) => {
    state.historyState = action.payload.historyState;
};

export const setCofirmStateFunc = (state:PageBaseState, action:PayloadAction<PageConfirmState>) => {
    state.confirmState = action.payload.confirmState;
};

export const errorFunc = (state:PageBaseState, action:PayloadAction<PageErrorState>) => {
    state.errorState = action.payload.errorState;
    state.errorDetail = action.payload.errorDetail;
};
