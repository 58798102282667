import { PayloadAction } from '@reduxjs/toolkit';

import {
    CampaignItemState,
    CampaignIsEntryState
} from '../../../type/stamp_props'

export const setCampaignItemFunc = (state:CampaignItemState, action:PayloadAction<CampaignItemState>) => {
    state.app_condition_list = action.payload.app_condition_list;
    state.app_period_end = action.payload.app_period_end;
    state.app_period_start = action.payload.app_period_start;
    state.banner_url = action.payload.banner_url;
    state.campaign_id = action.payload.campaign_id;
    state.disp_period_end = action.payload.disp_period_end;
    state.disp_period_start = action.payload.disp_period_start;
    state.freebie_content = action.payload.freebie_content;
    state.hold_status = action.payload.hold_status;
    state.is_entry = action.payload.is_entry;
    state.is_limited = action.payload.is_limited;
    state.point_limit = action.payload.point_limit;
    state.total_point = action.payload.total_point;
    // state.is_later = action.payload.is_later;
    state.sheet_number = action.payload.sheet_number;
    state.stamp_condition = action.payload.stamp_condition;
    state.stamp_list = action.payload.stamp_list;
    state.stamp_number = action.payload.stamp_number;
    state.target_period_end = action.payload.target_period_end;
    state.target_period_start = action.payload.target_period_start;
    state.target_prod = action.payload.target_prod;
    state.target_store = action.payload.target_store;
    state.title = action.payload.title;
    state.total_stamp = action.payload.total_stamp;
    state.unconsumed_stamp = action.payload.unconsumed_stamp;
    state.usage_notice = action.payload.usage_notice;
};

export const setCampaignIsEntryFunc = (state:CampaignItemState, action:PayloadAction<CampaignIsEntryState>) => {
    state.is_entry = action.payload.is_entry;
}
