import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { useSelector as rawUseSelector, TypedUseSelectorHook } from 'react-redux';

import viewSlice from './slice/viewSlice';
import authenticationSlice from './slice/authentication/authenticationSlice';
import historySlice from './slice/history/historySlice';
import campaignSlice from './slice/campaign/campaignSlice';
import applicationConditionSlice from './slice/application_condition/applicationConditionSlice';

import previewSlice from './slice/preview/previewSlice';
import previewDataSlice from './slice/preview_data/previewDataSlice';

const reducer = combineReducers({
    viewData: viewSlice,
    authentication: authenticationSlice,
    history: historySlice,
    campaignData:campaignSlice,
    applicationConditionData:applicationConditionSlice,
    previewViewData: previewSlice,
    previewData:previewDataSlice
});

export const store = configureStore({
    reducer
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;