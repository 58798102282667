import React from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from '../../store';
import { format, parseISO } from 'date-fns';

import { setChangeView } from '../../slice/viewSlice';

import {
    reactTagManagerEvDetailBeforeClickPrevBtn,
    reactTagManagerEvDetailAfterClickPrevBtn,
    reactTagManagerEvAppConfirmClickPrevBtn,
    reactTagManagerEvClickAppConfirmCompletePrevBtn
} from '../../../functions/function';

const BackList:React.FC = () => {
    const dispatch = useDispatch();
    const auth:any = useSelector((state) => state.authentication);
    const viewState:Number = useSelector((state) => state.viewData.viewState);
    const campaignData:any = useSelector((state) => state.campaignData);
    const appConditionData:any = useSelector((state) => state.applicationConditionData);

    const backListClick = () => {
        if(viewState === 1 && !campaignData.is_entry){
            reactTagManagerEvDetailBeforeClickPrevBtn(auth.data.res_data.send_id);
        }else if(viewState === 1 && campaignData.is_entry){
            reactTagManagerEvDetailAfterClickPrevBtn(auth.data.res_data.send_id);
        }else if(viewState === 2 && !appConditionData.is_apply){
            reactTagManagerEvAppConfirmClickPrevBtn(auth.data.res_data.send_id);
        }else if(viewState === 2 && appConditionData.is_apply){
            reactTagManagerEvClickAppConfirmCompletePrevBtn(auth.data.res_data.send_id);
        }

        dispatch(setChangeView({viewState: 0, prevViewState: viewState}));
    };

    return (
        <section className='mb-6 px-6 py-3 bg-white' onClick={backListClick}>
            <nav className='flex space-x-4 items-center'>
                <div className='lrArrow'>
                    <img src="img/icon_angle_left.png" className='w-full'/>
                </div>
                <div className='listBack'>
                    一覧へ戻る
                </div>
            </nav>
        </section>
    );
}

export default BackList;