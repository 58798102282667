import { PayloadAction } from '@reduxjs/toolkit';

import {
    AppConditionItemState
} from '../../../type/stamp_props'

export const setAppConditionItemFunc = (state:AppConditionItemState, action:PayloadAction<AppConditionItemState>) => {
    state.application_condition=action.payload.application_condition;
    state.banner_url=action.payload.banner_url;
    state.application_condition_id=action.payload.application_condition_id;
    state.is_apply=action.payload.is_apply;
    state.is_entry=action.payload.is_entry;
    state.num_stamp_require_app=action.payload.num_stamp_require_app;
    state.num_gift_point=action.payload.num_gift_point;
    state.remaining_stamp=action.payload.remaining_stamp;
    state.is_later=action.payload.is_later;
};