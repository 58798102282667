import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from '../../component/store';

import { useSelector } from '../store';

import LoginInput from './login/LoginInput';
import PreviewMain from './preview_main/PreviewMainWrap';
import ErrorAll from './error/ErrorAll';

import {
    setPreview,
    changeView,
    setPreviewError,
} from '../slice/preview/previewSlice';

import {
    previewData
} from '../slice/preview_data/previewData';

const PreviewWrap:React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const previewState:Number = useSelector((state) => state.previewViewData.viewState);

    useEffect(() => {
        const storage = localStorage;
        const loginedUserId = storage.getItem('userid');
        const loginedUserToken = storage.getItem('token');
        // storage.clear();

        if(loginedUserId && loginedUserToken){
            // console.log(storage.getItem('userid'));
            // console.log(storage.getItem('token'));
            const url:URL = new URL(window.location.href);
            const params:URLSearchParams = url.searchParams;
            const campaignIdParams:any = params.get('id');
            dispatch(setPreview({
                viewState: 1,
                detailId: campaignIdParams,
                tokenState: loginedUserToken,
                userIdState: loginedUserId
            }));
        }
    },[]);


    if(previewState === 0){
        return <LoginInput />
    }else if(previewState === 1){
        return <PreviewMain />
    }else if(previewState === 2){
        return <ErrorAll/>
    }else{
        return <></>
    }
}

export default PreviewWrap;